@import "../../assets/variableStyles.scss";

.combined-banner {
  .menu-items {
    li {
      color: #fff;
    }
  }
  .carousel-images {
    max-height: 100%;
    .image-container {
      position: relative;
      h4 {
        color: #fff;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        font-size: 30px;
        font-weight: 600;

        text-align: center;

        @include mobile {
          font-size: 17px;
          display: none;
          top: 50px;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  .slick-list {
    //height: 28em;
    @include mobile {
      height: 100%;
    }
  }
}

// .image-container {
//   background: #c10909;
//   height: 300px;
// }
.bg-v3-ban::before {
  background: #030a13;
  content: "";
  height: 100%;
  opacity: 0.45;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
.bg-v3-ban {
  background-image: url("../../assets/images/banner-all-panels.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  //padding: 15rem;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  height: 670px;
}
.air-cheap-v3 {
  text-align: center;
}
.book-airline-v33 {
  // top: -180px;
  // position: absolute;
  // left: 0;
  // right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff !important;
  font-weight: 700;
  font-size: 24px;
  //margin: -184px 0px 20px;
  margin: 60px 30px;
  @include mobile {
    display: none;
  }
}

.book-tree-v2 {
  animation: animate-left 0.4s ease-in-out;
  position: absolute;
  right: 0;
  top: 190px;
  width: 3rem;
  float: left;
  border: 0.0625rem solid #ccc;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: 0;
  display: block;
  background: #fff;
  margin: 0;
  padding: 0.2rem 0.5rem;
  list-style: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
}

.book-tree-v2 li {
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 0.5rem 0 0.2rem;
}
.drawer-v3-pop {
  .ant-drawer-content-wrapper {
    height: 200px;
    top: 293px;
  }
  .drawer-pop-img {
    text-align: center;
  }
  p {
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 15px;
    color: #000;
  }
  span {
    font-size: 13px;
    color: #000;
  }
  .likes-fb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #1877f2;
    color: #fff;
  }
  .anticon-close {
    font-size: 18px !important;
  }
  .ant-drawer-content {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

@include mobile {
  .why-book-trip {
    display: none;
  }
}
@media (max-width: 1024.98px) {
  .why-book-trip {
    display: none;
  }
}
