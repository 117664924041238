@import "../../assets/variableStyles.scss";

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .login-wrapper {
    max-width: 368px;
    padding: 24px;
    .otp-img {
      width: 110px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .otp-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 16px;
      border: 0px;
      border-radius: 8px;
      font-weight: 600;
      background-color: #009966;
      color: #ffffff;
      height: 48px;
      font-size: 14px;
      margin-top: 10px;
      &:hover {
        background-color: #005f3f !important;
        color: #ffffff !important;
      }
      &:focus {
        background-color: #005f3f !important;
        color: #ffffff !important;
      }
    }
    .google-btn {
      width: 100%;
      padding: 0px 16px;
      border-radius: 8px;
      font-weight: 600;
      height: 48px;
      font-size: 14px;
      background-color: #009966 !important;
      color: #fff !important;
      border-color: #009966 !important;
    }
    .google-btn:hover {
      color: #ffffff !important;
      border-color: #005f3f !important;
      background-color: #005f3f !important;
    }
  }
}

.modal-login-new-1 {
  border-radius: 8px;
  width: 368px !important;
  height: auto;
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-close-x {
    color: #000 !important;
  }
  .otp-shadow {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 30px 30px 6px 30px;
    background: #fff;
    margin-bottom: 20px;
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  .otp-margin-bottom {
    .ant-input {
      letter-spacing: 2.5px;
      font-weight: 600;
      text-align: center;
      border-color: #efefef !important;
      color: #434343;
      box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: #707070;
      }
    }
  }
  .google-btn {
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    height: 48px;
    font-size: 14px;
    background-color: #05004e !important;
    color: #fff !important;
    border-color: #05004e !important;
  }
  .google-btn:hover {
    color: #ffffff !important;
    border-color: #05004e !important;
    background-color: #05004e !important;
  }
  .otp-img-1 {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .otp-shadow-1 {
    padding: 5px;
  }
  .otp-shadow-value {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 9px;
    background: #fff;
    margin-bottom: 3px;
  }
  .otp-img {
    width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-modal-footer {
    display: none;
  }
  .countdown {
    text-align: center;
  }
}
.modal-login-new-1 .ant-statistic-content-value {
  color: #e12001 !important;
}
.login-modal {
  .form-input {
    margin: 14px 10px;
    .ant-input {
      padding: 12px 18px;
      border-radius: 5px;
    }
  }
  .form-btn {
    padding: 0.7rem 1rem;
    font-size: 0.9375rem;

    border-radius: 0.3125rem;
    color: #fff;
    background-color: #006ee3;
    border-color: #006ee3 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.3);
    width: auto !important;
    height: 100%;
  }
  .modal-section-left {
    padding: 24px !important;
    background: #0c2243;
    color: #fff;
    @include mobile {
      display: none;
    }
    h2 {
      color: #fff;
    }
    .icons {
      font-size: 30px;
      margin: 0 10px 0 0;
    }
  }
  .modal-section-right {
    padding: 24px !important;
  }
  .otp-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    border: 0px;
    border-radius: 8px;
    font-weight: 600;

    color: #ffffff;
    height: 48px;
    font-size: 14px;
    margin-top: 10px;
    &:hover {
      color: #ffffff !important;
    }
    &:focus {
      color: #ffffff !important;
    }
  }
  .google-btn {
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    height: 48px;
    font-size: 14px;
    background-color: #009966 !important;
    color: #fff !important;
    border-color: #009966 !important;
  }
  .google-btn:hover {
    color: #ffffff !important;
    border-color: #005f3f !important;
    background-color: #005f3f !important;
  }
  .back-btn {
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    height: 48px;
    font-size: 14px;
    background-color: #ce1212 !important;
    color: #fff !important;
    border-color: #ce1212 !important;
  }
  .back-btn:hover {
    color: #ffffff !important;
    border-color: #ce1212 !important;
    background-color: #ce1212 !important;
  }
  .otp-img {
    width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .otp-shadow {
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}
