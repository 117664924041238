@import "../../assets/variableStyles.scss";
$primary: #e12001;
$secondry: #05004e;
body {
  font-family: Arial, Helvetica, sans-serif !important;
}
.combined-home {
  .header {
    position: absolute;
    width: 100%;
    background: transparent;
  }
  .search-wrapper {
    max-width: 1200px;
    // margin: -600px auto 38%;
    padding: 0 15px;
    margin: 0 auto;
    @include mobile {
      // margin: -700px auto 123px;
    }
  }
  .home-cards {
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #e12001;
    }
  }
  .planning-block {
    background-color: #fff;
    border-top: 1px solid #ccc;
    .planning-block-row {
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px 15px;
      display: flex;
      align-items: center;

      .image-container {
        background-image: url("../../assets/images/rep-icon.svg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 20em;
      }
      .planning-text {
        h1 {
          font-size: 30px;
          font-weight: 600;
          color: #e12001;
          @include mobile {
            font-size: 23px;
            font-weight: 600;
            line-height: 30px;
          }
        }
      }
    }
  }
  .stay-tuned-block {
    .stay-tuned-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px 15px;
    }
    background-color: #e4d7ca;
    text-align: center;

    h2 {
      color: #e12001;
      margin-bottom: 16px;
      font-size: 30px;
      font-weight: 600;
      color: #e12001;
    }
    p {
      color: #fff;
      margin-bottom: 16px;
      font-size: 16px;
    }
    .staytuned-input {
      @media (max-width: 768px) {
        width: 100%;
      }

      .ant-input-affix-wrapper {
        height: 48px;
        border-radius: 8px;
        border-color: #efefef !important;
        .ant-input-prefix {
          color: $primary !important;
        }
      }

      button {
        background-color: #009966;
        outline: none;
        border: 1px solid #009966;
      }
    }
  }
  .cards-flex {
    display: flex;
    .ant-card {
      width: 100%;
      text-align: center;
      .ant-card-body {
        @include xsmobile {
          padding: 10px 5px;
        }
      }
      .card-icons {
        font-size: 25px;
      }
    }
    img {
      height: 65px;
      width: 65px;
      margin-bottom: 15px;
    }
  }
  .content-wrapper {
    .content-cards {
      max-width: 1200px;
      margin: 0px auto;
      padding: 0 15px;
    }
  }
  .top-routes {
    border-top: 1px solid #ccc;
    background-color: #fff;
    .top-routes-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px 15px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .top-bus-op {
      flex: 1;
      max-width: 330px;
    }

    .top-bus-routes,
    .top-bus-op,
    .top-flight-routes {
      .top-bus-heading,
      .top-train-heading,
      .top-trains-heading {
        h4 {
          font-size: 23px;
          margin-bottom: 15px;
        }
      }
      .top-bus-content,
      .top-trains-content,
      .top-flight-content {
        ul {
          li {
            color: #05004e;
            margin-bottom: 10px;
            font-size: 14px;
            &:hover {
              color: #e12001;
            }
          }
        }
      }
    }
  }
  .adv-banner {
    background-color: #05004e;

    .adv-banner-container {
      max-width: 1200px;
      margin: 0 auto;

      padding: 50px 15px;

      .adv-text {
        h3 {
          color: #e12001;
          font-size: 30px;
          margin-bottom: 18px;
          font-weight: 600;
          font-family: GT Walsheim, Helvetica, Arial, sans-serif;
          @include mobile {
            font-size: 23px;

            font-weight: 600;
            line-height: 30px;
            text-align: center;
            font-family: GT Walsheim, Helvetica, Arial, sans-serif;
          }
        }
        p {
          color: #fff;

          @include mobile {
            font-size: 16px;
            text-align: center;
          }
        }
      }
      .adv-row {
        align-items: center;
      }
    }
    .image-carousel {
      img {
        height: 420px;
        margin: auto;
      }
    }
  }
}
.play-store-btns {
  img {
    height: 60px;
    margin-right: 25px;
    @include mobile {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }
}

.tg-primary-btn {
  background-color: #009966;
  color: #fff;
  border-color: #009966;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  &:hover {
    background-color: #005f3f;
    border-color: #005f3f;
    color: #fff;
  }
  &:active {
    background-color: #005f3f;
    border-color: #005f3f;
    color: #fff;
    height: 48px;
    border-radius: 8px;
    font-weight: 700;
  }
}
.travel-21 h5 {
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #425486;
  text-align: center;
}

.travel-21 p {
  color: #425486;

  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  text-align: center;
}
.travel-21 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tarvel-22 p {
  color: #05004e;

  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  text-align: center;
}
.travels-bar {
  /*  border-bottom: 1px solid #ccc; */
  margin-top: 20px;
}
// .ant-btn-primary {
//     background-color: #09a651;
//     border: none;
//     &:hover {
//     background-color: #09a651;
//     }
// }
.search-wrapper .combined-search-block {
  border-bottom: 0px solid #d9d9d9;
  border-top: 0px solid #d9d9d9;
}

.planning-text-mobile p {
  color: #05004e;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}
.adv-text p {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}
@media screen and (max-width: 768px) {
  .search-wrapper .ant-collapse-header {
    display: block;
    color: #000000 !important;
    font-size: 23px;
    display: none;
  }
  .planning-text-mobile {
    margin-top: 20px;
  }

  .planning-text-mobile h1 {
    text-align: center;
  }

  .planning-text-mobile p {
    text-align: center;
  }
  .image-container {
    background-position: center;
  }
  .play-store-btns {
    margin-bottom: 25px;
  }
  .tg-primary-btn {
    width: 100%;
  }
}

.bus-to-from {
  font-size: 26px !important;
  font-weight: 700;
}
.buses-route-ul li {
  color: #444 !important;
  font-size: 14px !important;
  line-height: 14px;
  font-style: normal;
  span {
    cursor: pointer;
  }
}

.buses-route-ul-mobile li {
  color: #05004e !important;
  font-size: 16px !important;
  line-height: 24px;
  font-style: normal;
  padding-bottom: 10px;
  span {
    cursor: pointer;
  }
}

.top-routes-mobile {
  /*  padding: 50px 20px; */
  display: none;
}
.top-routes-mobile {
  /* padding: 50px 20px; */
  background-color: #fff;
}
.top-routes-container-mobile {
  max-width: 1200px;
  margin: auto;
  padding-left: 40px;
  @include mobile {
    padding-left: 0px;
  }
}
.top-routes-mobile {
  display: none;
}
.routes-block-new {
  display: none;
}
@media only screen and (max-width: 768px) {
  .top-routes-mobile {
    display: block !important;
  }
  .new-top-all-routs {
    display: none;
  }
  .bus-routes-collaspe {
    background-color: #c10909 !important;
    border: 1px solid #eee !important;
  }
  .bus-routes-collaspe .ant-collapse-item {
    border-bottom: 1px solid #eee;
  }
  .bus-routes-collaspe .ant-collapse-header {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
  }
}

.buses-route-ul-top li {
  color: #425486 !important;
  font-size: 16px !important;
  line-height: 7px;
  font-style: normal;
  display: inline-block;
  padding: 0px 10px 10px 0px;
}
.planning-block {
  display: none;
}
.adv-banner {
  display: none;
}

.directly-flight-ofc {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0 15px;
}

.directly-flight-ofc-line {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0 15px;
}
.directly-flight-ofc-v3 {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0 15px;
}
.offers-promo-one {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0 15px;
}
.fli-v1 {
  width: 92%;
}
.fli-v1 h5 {
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #444 !important;
}
.fli-v1 p {
  font-size: 12px;
  text-align: justify;
  color: #444;
}
.line-v3-one {
  border-top: 1px solid #ccc;
}
.pdr-v3-line {
  padding: 20px 15px;
}
.dm-flight a {
  color: #444;
  line-height: 20px;
  font-size: 12px;
  word-wrap: break-word;
}

.fli-v12 {
  width: 92%;
}
.fli-v12 h5 {
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #444 !important;
}
.fli-v12 p {
  font-size: 14px;
  text-align: justify;
}
.bus-to-from {
  color: #444 !important;
}

@media screen and (max-width: 768px) {
  .fli-v1 {
    width: 100%;
  }
  .fli-v12 {
    width: 100%;
  }
  .ofc-panel-offers {
    width: 100%;
  }
  .directly-flight-ofc {
    display: none;
  }
  .pdr-v3-line {
    padding: 2px 15px;
  }
  .last-bottom-panel {
    display: none !important;
  }
  .search-wrap-two {
    top: -713px !important;
  }
}
.tuned-v3 {
  display: none;
}
.v3-wrapper-22 {
  display: none;
}
.directly-flight-ofc-v3 {
  margin-top: 25px;
}
.top-routes-mobile-1 {
  display: none;
}

.wrapper-one-1 {
  position: relative;
}

.search-wrap-two {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -605px;
}

.position-delas-1 {
  position: relative;
}
.position-delas-2 {
  position: absolute;
  z-index: 8;
  width: 100%;
  top: -300px;
  @include mobile {
    position: static;
  }
}