@import "../../../assets/variableStyles.scss";

.trip-contents {
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;

  @include mobile {
    padding-left: 5px;
  }
  &:hover {
    background: var(--leg-hover-color, rgba(234, 235, 238, 0.6));
  }
  input {
    margin-right: 10px;
  }
}

.trip-content-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include mobile {
    flex-direction: column;
  }
}
.trip-arr-dep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
  @include mobile {
    flex-direction: column;
  }
}

.timings {
  i {
    color: #1c2b39;
    font-size: 15px;
    padding: 1px 5px;
  }
}
.duration {
  padding-left: 20px;
  text-align: center;
  @include mobile {
    padding-left: 5px;
  }
  i {
    @include mobile {
      display: none;
    }
  }
}
.show-more-btn {
  padding: 4px 4px 0px 80px;
  @include mobile {
    padding: 4px 4px 0px 26px;
  }
  button {
    border: solid 1px #0775e2;
    color: #0775e2;
    line-height: 25px;
    font-size: 12px;
    font-weight: 700;
    &:hover {
      background: none;
      color: #0775e2;
      font-weight: 700;
    }
    i {
      color: #0775e2;
      font-weight: 700;
    }
  }
}
.share-section {
  font-size: 17px;
  color: #007baa;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.currencyType {
  color: #969dac;
  font-size: 18px;
  letter-spacing: 1px;
  padding-right: 4px;
  font-weight: 600;
}
.different-amenities {
  font-size: 16px;
  color: rgb(29, 160, 35);
}

.ament-tooltip {
  padding-left: 40px;
}
.amenities-tooltip .amenities-tooltip > .tooltip-inner {
  min-width: 450px !important;
  @include mobile {
    max-width: 300px !important;
  }
}
.flightTooltip {
  max-width: auto !important;

  @include mobile {
    max-width: 300px !important;
  }
}

.tooltip-content {
  display: flex;
  justify-content: center;
  padding: 20px 20px !important;

  .flight-amenities {
    padding: 0px 5px;
    .flight-src-dest {
      h6 {
        color: #fff;
        padding-bottom: 10px;
      }
    }
    .amenity-types {
      p {
        line-height: 1.4;
        font-size: 12px;
        i {
          padding-right: 6px;
        }
      }
      .aminities-color {
        color: #1da023;
        font-size: 12px;
      }
    }
  }
}

.trip-total-details {
  @include mobile {
    order: 1;
  }
}
.trip-choose-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: solid 1px #ccc;
  @include mobile {
    order: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 0px 30px;
  }
}

/*********** Antd styles ********/
.flight-results-container {
  .ant-card-body {
    padding: 0;
  }
  .ant-row {
    @include mobile {
      flex-wrap: wrap-reverse;
    }
  }
  .flight-trip-details {
    background-image: linear-gradient(180deg, #fafcfd, #fff);

    .itinary-details {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include tab {
        flex-direction: column;
      }
      .innerwrapper {
        flex: 1;
        max-width: 720px;
        .innerContent {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .itinary-flight-name {
        @include tab {
          display: flex;
          align-items: center;
          flex: 1 0 62%;
          order: 1;
        }
        .airline-img {
          max-width: 35px;
        }
        .name {
          font-size: 14px;
          margin-top: 6px;
          margin-bottom: 2px;
          font-weight: 600;
          @include tab {
            margin-left: 3px;
          }
          .code {
            font-weight: 500;
            font-size: 12px;
          }
          @include mobile {
            font-size: 12px;
            .code {
              font-size: 10px;
            }
          }
        }
      }

      .fromTo {
        display: flex;
        flex: 1;
        padding: 0 10px;
        justify-content: center;

        @include tab {
          flex: 0 1 100%;
          order: 3;
          padding: 10px 0 12px 0;
        }
        @include mobile {
          margin-left: 0px;
          font-size: 15px;
          font-weight: 700;
          flex-direction: column;
          justify-content: flex-start;
        }
        .cities-from-to {
          display: flex;
          flex-direction: column;
          position: relative;
          padding-right: 10px;
          flex: 1;
          @include mobile {
            padding-right: 0;
          }
          .loc_wrapper,
          .terminals {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-weight: 600;

            @include mobile {
              p {
                margin-bottom: 2px;
              }
            }
          }
          .loc_wrapper {
            .name {
              font-weight: 600;
              font-size: 16px;
              @include xsmobile {
                font-size: 14px;
              }
            }
            .code {
              font-weight: 600;
              font-size: 12px;
              margin-left: 2px;
              @include xsmobile {
                font-size: 10px;
              }
            }
          }
        }
      }
      .available-price-details {
        text-align: right;
        @include tab {
          order: 4;
          text-align: left;
        }
        @include mobile {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          align-items: center;
        }
        .starting-price {
          margin-bottom: 5px;
          padding-left: 10px;
          @include tab {
            padding: 0;
          }
          @include mobile {
            margin-bottom: 0;
            text-align: start;
            flex: 1;
          }
          .starting-price-text {
            font-size: 11px;
            color: #969dac;
            margin-bottom: 0;
            font-weight: 400;
          }
          .starting-price-number {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
            color: #444;
            @include mobile {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .code-line {
    width: 50%;
    height: 2.4px;
    background: #0775e2;
    margin: auto;
    @include mobile {
      font-size: 12px;
      padding: 0px 15px;
      align-items: center;
      text-align: center;
      width: 10%;
    }
  }
  .flight-class-details {
    text-align: end;
    @include tab {
      flex: 1 0 50%;
      order: 2;
    }
  }
}

/******************* Flight Choose button section ***********/
.choose-btn-wrapper {
  background: #fff;
  height: 100%;
}

.choose-btn-block {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .infowrapper {
    .infobtnwrapper {
      display: flex;
      align-items: center;
      .title {
        color: #1c2b39;
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .choose-btn-price {
    @include mobile {
      margin-bottom: 0;
    }
  }
}
.choose-btn-section {
  @include mobile {
    order: 1;
  }
}
.btn-section {
  @include mobile {
    display: flex;
    .share-section {
      margin-right: 10px;
    }
  }
}
.choose-button {
  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 10px;
  }
}

/******************* Flight Timings ***********/
.flight-timings {
  display: flex;
  align-items: center;

  justify-content: space-between;
  font-weight: 700;
  @include mobile {
    margin-top: 5px;
  }
  .refundText {
    font-size: 12px;
    color: #262626;
  }
  .timing_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    @include mobile {
      justify-content: flex-start;
    }

    .plane-timings {
      display: flex;

      .timings-container {
        margin-right: 20px;
        @include mobile {
          margin-right: 0;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          margin-right: 10px;
          span {
            display: inline-block;
            vertical-align: middle;
            color: #000000;
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: bold;
            @include mobile {
              font-size: 14px;
            }
            .date {
              font-weight: normal;
            }
          }
        }
      }
      @include xsmobile {
        ul {
          li {
            margin-right: 4px;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
    .travel-time {
      display: flex;
      align-items: center;
      font-size: 14px;
      p {
        margin-bottom: 0;
        margin-left: 5px;
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .details-btn {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: flex-end;
    width: fit-content;
    p {
      margin-bottom: 0;
    }
    .details-text {
      font-size: 12px;
      color: #0775e2;

      cursor: pointer;
    }
    .details-bag-icon {
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
}
.flight-details-modal {
  width: 840px !important;
  .ant-modal-body {
    padding: 0;
    max-height: 500px;
    overflow-y: scroll;
  }
  .ant-modal-footer {
    display: none;
  }
  .all-times-local {
    padding: 16px 24px;
    border-bottom: 1px solid #ccc;
    @include mobile {
      text-align: center;
    }
    p {
      margin-bottom: 0;
    }
  }

  .more-details-row {
    .cities-names {
      @include mobile {
        width: 100%;
      }
    }
    .more-details-list {
      max-width: fit-content;
      &:nth-child(2) {
        @include mobile {
          text-align: end;
        }
      }
      p {
        &:nth-child(1) {
          font-size: 16px;
          text-transform: uppercase;
          color: #00164d;
          margin-bottom: 0;
          @include mobile {
            font-size: 14px;
          }
          @include xsmobile {
            font-size: 12px;
          }
        }
        &:nth-child(2) {
          margin-bottom: 0;
          font-size: 12px;
          @include xsmobile {
            font-size: 10px;
          }
        }
        .code {
          font-size: 12px;
          margin-left: 5px;
          font-weight: 600;
          @include xsmobile {
            font-size: 10px;
          }
        }
      }
      @include mobile {
        max-width: 100%;
      }
    }
    .more-details-list-to {
      @include mobile {
        text-align: end;
      }
    }
    .more-details-line {
      height: 2px;
      background-color: #0775e2;
      margin: auto;
    }
    .trip-duration-time {
      p {
        &:nth-child(1) {
          color: #969dac;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #00164d;
          margin-bottom: 0;
          font-weight: 600;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
  .total-flight-details {
    border-bottom: 2px solid #fff;
    padding: 14px 24px 14px 24px;
    background-color: #f6f7f8;
    @include mobile {
      padding-left: 40px;
    }
    .to-fro-flight-details {
      display: flex;
      position: relative;
      @include mobile {
        flex-direction: column;
      }
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #0775e2;
        position: absolute;
        left: -33px;
        top: 4px;
      }
      .from-city-flight-details {
        margin-right: 25px;
        @include mobile {
          display: flex;
          margin-right: 0;
        }
      }
    }
    .collapsed-card {
      padding: 5px 0;
      position: relative;
      &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: #0775e2;
        width: 3px;
        left: -29px;
        top: 0;
      }
      .ant-card-body {
        padding: 0;
      }
      .ant-collapse {
        border: none;
      }
      .ant-collapse-item {
        border: none;
      }
      .ant-collapse-header {
        background-color: white;
      }
    }
  }
}

.acc-body {
  .acc-body-section {
    display: flex;
    padding: 7px 2px;
    align-items: baseline;
    .icon {
      margin-right: 7px;
      color: #05004e;
      font-weight: 700;
    }
    p {
      color: #05004e;
      font-weight: 700;
    }
    &:nth-child(1) {
      padding-right: 25px;
    }
    .refundable {
      color: #1da023;
      padding-left: 12px;
    }
    .non-refundable {
      color: red;
      padding-left: 12px;
    }
  }
  .seat-sec {
    i {
      padding-right: 7px;
      color: #05004e;
      font-weight: 700;
    }
    p {
      color: #05004e;
      font-weight: 700;
    }
  }
  .flight-class {
    .ec-class {
      padding-left: 12px;
    }
    span {
      font-weight: 700;
    }
  }
}
.tooltip-data {
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: 12px;
    color: #fff;
    margin-bottom: 0;
  }
}
// .ant-tooltip-content {
//   min-width: 100% !important;
//   border-radius: 10px;
//   .ant-tooltip-inner {
//     padding: 10px 15px;
//   }
// }

.luggage-tooltip {
  p {
    color: #1da023;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    .ant-icon,
    i {
      font-size: 18px;
      margin-right: 5px;
    }
    &:nth-child(1) {
      color: #fff;
    }
    &:nth-child(2) {
      color: #fff;
    }
  }
}
.collapse-header {
  .flight-icon {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }
    .flight-names-block {
      p {
        font-size: 12px;
        margin-bottom: 0;
        &:nth-child(1) {
          font-size: 20px;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }
  .ant-row {
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .modal-flight-icon {
    i {
      margin-right: 10px;
      font-size: 18px;
      @include mobile {
        font-size: 15px;
      }
    }
    span {
      @include mobile {
        font-size: 13px;
      }
    }
  }
  .timer-flight-icon {
    span {
      margin-right: 10px;
      @include mobile {
        font-size: 13px;
      }
    }
    .icon {
      font-size: 18px;
      @include mobile {
        font-size: 15px;
      }
    }
  }
}
.combined-view {
  //   margin-bottom: 10px;
  height: 100%;
  .flight-trip-details {
    padding: 15px;
  }
}
/*********** Seperate View ******/
.seperate-view {
  margin-bottom: 10px;
  .right-flight-details {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    &:last-child {
      border: none;
    }
  }
}
.break-journey-city {
  // height: 2px !important;

  background-color: #0775e2;
  position: relative;
  margin: 0 5px;
  flex: 1;
  .inner_wrapper {
    position: absolute;
    width: 100%;
    border-top: 2px solid #0775e2;
    text-align: center;
    .stop_points {
      display: inline-block;
      margin: 5px;
    }
  }

  .code-line-break {
    height: 7px;
    width: 7px;
    background-color: #0775e2;
    border-radius: 50%;
    margin: auto;
    margin-top: 12px;
    &::before {
      content: "";
      background-color: #0775e2;
      width: 20%;
      // top: 22px;
      height: 2px;
      left: 50px;
      position: absolute;
      @include mobile {
        width: 10%;
        left: 45px;
      }
    }
    &::after {
      content: "";
      background-color: #0775e2;
      width: 20%;
      // top: 22px;
      height: 2px;
      right: 47px;
      position: absolute;
      @include mobile {
        width: 10%;
        right: 45px;
      }
    }
  }
  .break-city-name {
    font-size: 12px;
    text-transform: uppercase;
    @include xsmobile {
      font-size: 10px;
    }
    &::before {
      position: absolute;
      height: 7px;
      top: -8px;
      width: 7px;
      margin: 0 5px;
      background-color: #0775e2;
      border-radius: 50%;

      border: 4px solid white;
      box-sizing: content-box;
      content: "";
    }
  }
}
.change-timer {
  background-color: #eaebee;
  padding: 24px 60px;
  display: flex;
  align-items: center;
  .change-text {
    color: #e0760b;
    font-weight: 600;
    margin-right: 30px;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
    }
    .icon-text {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .time-for-interchange {
    font-size: 13px;
  }
}
.combined-flight-results-container {
  height: 100%;
  .combined-flight-card {
    height: 100%;
    .ant-card-body {
      height: 100%;
      .ant-row {
        height: 100%;
      }
      .flight-trip-details {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .flight-timings {
          flex-wrap: wrap;
          .timing_wrapper {
            flex: initial;
          }
        }
      }
    }
  }
}
.share-modal {
  width: 290px !important;
  border-radius: 10px;
}
.share-link-icons {
  display: flex;
  .common-share-icons {
    margin: 15px 30px;
    text-align: center;
    .anticon {
      font-size: 25px;
      color: #0775e2;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.flight-infoModal {
  text-align: center;
  .ant-modal-body {
    padding: 18px !important;
    text-align: center;
  }
  .ant-btn {
    margin-top: 15px;
  }
}
.ant-modal-header {
  background-color: #009966 !important;
  color: white !important;
  border-color: #009966 !important;
}

/* 02-12-2020--changes */

.flights-btn-view {
  background-color: rgba(0, 0, 0, 0.03);
  // background-image: linear-gradient(180deg, #fafcfd, #fff);
  border-radius: 0 0 8px 8px;
  box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
}
@media screen and (max-width: 768px) {
  .type-fun-1 {
    position: absolute;
    top: 110px;
    right: 136px;
    font-weight: normal;
    z-index: 1;
  }
  .stopsType {
    position: absolute;
    top: 110px;
    right: 84px;
    font-weight: normal;
    z-index: 2;
  }
  .loc_wrapper .name {
    font-size: 12px !important;
  }
  .itinary-details .fromTo {
    padding: 0px 0 0px 0 !important;
  }
  .type-fun-2 {
    position: absolute;
    top: 12px;
    right: 24px;
    font-weight: normal;
  }
  .plane-timings ul li p {
    font-size: 12px !important;
    color: #262626;
  }

  .share-section {
    position: absolute;
    top: 12px;
    right: 0;
  }
  .starting-price {
    position: absolute;
    right: 0;
    top: 63px;
  }
  .itinary-details {
    flex-direction: initial !important;
  }
  .plane-timings ul li {
    margin-right: 2px !important;
  }
  .flight-class-details {
    display: block !important;
    flex-direction: column-reverse !important;
    text-align: right !important;
  }
  .itinary-flight-name .airline-img {
    max-width: 25px !important;
  }
  .flight-class {
    position: absolute;
    top: 26px;
    right: 4px;
    font-weight: normal !important;
  }
}
.halt-wrapper {
  padding: 10px 5px;

  p {
    font-weight: 600;
  }
  @include mobile {
    padding: 5px;
  }
}
