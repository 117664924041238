@import "../../assets/variableStyles.scss";
.dashboard {
  padding: 15px;
  .dash-board-header {
    display: flex;
    justify-content: space-between;
    .dash-board-title {
      h4 {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
    .dash-board-header-btn {
      button {
        background-color: #007bff;
        display: flex;
        border: none;
        align-items: center;
        padding: 7px 10px;
        color: #fff;
        border-radius: 5px;
        p {
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
  .order-count-cards {
    .flights-sales-card {
      font-weight: bold;
      font-size: 16px;
    }
    .ad-hom-box {
      background: #fff;
      height: 100%;
      display: flex;
      align-items: stretch;
      border-radius: 5px;
      box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
      overflow: hidden;
    }
    .ad-span-col {
      background: #167ee6b8;
      flex: 1;
      color: #fff;
      font-size: 20px;
      text-align: center;
      max-width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bg-1 {
      background: #4caf50bf;
    }
    .bg-2 {
      background: #4c5cafc7;
    }
    .bg-3 {
      background: #da0c4d9c;
    }
    .bg-4 {
      background: #00b29794;
    }
    .bg-5 {
      background: #1d2939ad;
    }
    .bg-6 {
      background: #0866c6a1;
    }
    .bg-7 {
      background: #f49917ad;
    }
    .bg-8 {
      background: #17a2b894;
    }
    .bg-9 {
      background: #f764009c;
    }
    .bg-10 {
      background: #0061f294;
    }
    .bg-11 {
      background: #00ac69a1;
    }
    .bg-12 {
      background: #e5e636ad;
    }
    .bg-13 {
      background: #9e2c779c;
    }
    .ad-hom-view-com {
      text-align: center;
      flex: 2;
      padding: 0 0px;
      word-break: break-word;
      p {
        color: #9a9a9a;
        text-align: center;
        margin-bottom: 0px;
        font-size: 13px;
      }
      h5 {
        margin-bottom: 0px !important;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        @include mobile {
          font-size: 16px;
        }
      }
    }

    .month-wise-cart {
      font-weight: 700;
      font-size: 16px;
    }
    .ant-card {
      border-radius: 5px;
      .ant-card-body {
        padding: 10px;
      }
    }

    .order-card {
      // background-color: #007bff;
      // border-radius: 5px;
      // color: #fff;
      .count-dashboard {
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
      }
      .bottom-dashboad-bdr {
        display: block;
        margin: 4px -15px -15px;
        padding: 2px 15px;
        background: rgba(0, 0, 0, 0.4);
        text-align: right;
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
        border-radius: 5px;
      }
      .bottom-dashboad-bdr:hover {
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
      }
    }
    .dashboard-card {
      .ant-card-body {
        padding: 15px !important;
        .dash-board-icon-all {
          font-size: 42px !important;
          height: 56px !important;
          width: 56px !important;
          text-align: center !important;
          line-height: 56px !important;
          color: rgba(0, 0, 0, 0.15) !important;
        }
        .dash-boadrd-heading {
          font-size: 14px;
          margin: 5px 0;
          color: rgba(255, 255, 255, 0.7);
          font-weight: 400;
        }
        .bottom-dashboad-bdr-4:hover {
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
        }
        .dashboard-datails-p {
          margin-top: 3px;
          margin-bottom: 2px;
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
    .card-bg-1 {
      background-color: #348fe2 !important;
    }

    .card-bg-2 {
      background-color: #ff5b57 !important;
    }

    .card-bg-3 {
      background-color: #ef9d9b !important;
    }
  }
}
.order-count-cards {
  margin-top: 20px;
  margin-bottom: 20px;
}
