.actionable-buttons {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 16px !important;
      padding-left: 40px !important;
      .ant-collapse-arrow {
        width: auto !important;
        left: 16px !important;
      }
    }
  }
}
