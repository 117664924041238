@import "../../../assets/variableStyles.scss";

.hotels_search_container {
  .hotel_banner {
    // position: relative;
    // z-index: 1;
    .heading_text {
      // position: absolute;
      // z-index: 2;
      // left: 50%;
      // transform: translate(-50%, 50%);
      // text-align: center;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      // top: 7%;
      @include mobile {
        position: static;
        transform: none;
        //background-color: #002172;
        display: none;
      }
      h2 {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
      ul {
        display: flex;
        justify-content: center;
        li {
          position: relative;
          padding-right: 40px;
          font-size: 18px;
          color: #fff;
          &:not(:last-child)::after {
            content: " ";
            background-color: #fff;
            width: 5px;
            height: 5px;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            right: 20px;
          }
          &:last-child {
            padding-right: 0;
          }
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      @media screen and (max-width: 768px) {
        top: 8px;
        padding: 24px 16px;
        bottom: auto;
      }
    }

    .hotel_search {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, 0);
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      @include mobile {
        position: static;
        transform: none;
      }
    }

    .hotels_search_box {
      border-radius: 0;
      @include mobile {
        border-bottom: 0px solid #dcdee3;
      }
    }
  }
  .hotels_advantages {
    max-width: 990px;
    margin: 70px auto;
    .hotels-advantages-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .hotels-adv-box {
        display: flex;
        align-items: center;
        flex-basis: 45%;
        flex-shrink: 0;
        flex-grow: 0;
        .hotels-adv-box-wrapper {
          padding-left: 15px;
          p {
            font-weight: 700;
            margin-bottom: 3px;
            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }
          small {
            font-size: 90%;
          }
          @media screen and (max-width: 768px) {
            text-align: center;
            width: 90%;
            line-height: 1.3;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
    @include mobile {
      padding-top: 0px;
    }
  }

  .queries_about_hotels {
    background-color: #f6f7f8;

    .query-container {
      max-width: 990px;
      margin: auto;
      padding: 56px 0;
      .query-hotels-heading {
        text-align: center;
        color: #1c2b39;
        h2 {
          font-size: 27px;
          line-height: 35px;
          font-weight: 300;
        }
        p {
          font-size: 18px;
          margin-bottom: 30px;
        }
        @include mobile {
          padding: 0 15px;
        }
      }
      .query-hotels-body {
        display: flex;
        flex-wrap: wrap;
        .query-box {
          box-shadow: 0 1px 4px 1px #dcdee3;
          background: #fff;
          margin-bottom: 25px;
          padding: 0;
          .box-head {
            img {
              width: 100%;
              height: 100%;
              max-height: 157px;
              object-fit: cover;
            }
          }
          .box-body {
            padding: 20px;
            a {
              color: #1c2b39;
              font-weight: 700;
              font-size: 16px;
              &:hover {
                text-decoration: underline;
              }
            }
            p {
              font-size: 15px;
              margin-bottom: 0;
              margin-top: 16px;
              line-height: 1.5;
              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
        .full-box {
          padding: inherit;
          padding-top: 20px;
          h3 {
            font-size: 13px;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
          }
          .box-body {
            padding-left: initial;
            [class*="col-"] {
              @include mobile {
                margin-bottom: 1rem;
              }
            }
            a {
              color: #0775e2;
              font-size: 14px;
              font-weight: initial;
              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
      }
      .query-hotels-footer {
        text-align: center;
        button {
          font-weight: 700;
          border-radius: 3px;
          padding: 8px 23px;
        }
      }
    }
  }
}

.cart-shopping-img {
  width: 100%;
  height: 100%;
  max-height: 228px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.cart-shopping-img:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
}
.cart-h4 {
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  color: #fff !important;
  font-weight: bold;
}
.card-container-1 {
  max-width: 990px;
  margin: auto !important;
  padding: 56px 0 !important;
}
.heading-hotel {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 25px;
}
.heading-hotel h3 {
  text-align: center;
  font-size: 24px;
  color: #1c2b39;

  font-weight: 600;
}
.heading-hotel p {
  text-align: center;
  font-size: 18px;
}
.states-hotel {
  font-size: 13px;

  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.cart-h4 h4 {
  text-align: center;
  font-size: 20px;
  line-height: 1.1;
  text-shadow: 0px 0px 5px rgba(66, 103, 178, 0.75);
  color: #ffffff;
  font-weight: 700;
}
.row-second-1 {
  margin-top: 25px;
}
@media screen and (max-width: 767px) {
  .hotel_banner {
    // background-color: #2f81ed;
    // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
    padding-bottom: 10px;
  }
}

.background_banner {
  // height: 337px;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  //background-color: #00000042;
  background-image: url("../../../assets/images/v3-panel-11.jpg");
  padding-top: 8%;
  padding-bottom: 8%;
}

.delas-phone-v3 {
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
}
.icon-mob-21 {
  background: #009966;
  height: 26px;
  width: 26px;
  line-height: 26px !important;
  font-size: 18px;
  border-radius: 20px;
  text-align: center;
}
.delas-phone-v3 span {
  color: #009966;
}
