@import "../../assets/variableStyles.scss";

.checkout-heading {
  padding-bottom: 15px;

  .goback {
    margin-bottom: 10px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    color: #444444;
    cursor: pointer;
    .anticon-arrow-left {
      margin-right: 7px;
    }
  }

  h3 {
    color: #000000;
    margin-bottom: 0px;
    font-size: 20px;
  }
}
.checkout-section {
  @include mobile {
    padding-top: 0px;
  }
  .service-cahrge-table {
    .service-charge-header {
      background-color: #1890ff;
      ul {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        li {
          color: #fff;
        }
      }
    }
    .service-charge-body {
      ul {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border: 1px solid #969dac;
        border-top: none;
      }
    }
  }
}
.flight-wrapper {
  background: #eaebee;
}
.flight-checkout {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  .review-container {
    @include mobile {
      margin-top: 55px !important;
    }
  }

  .checkout-sticky-part {
    position: sticky;
    top: 0;
    .grand-total-card {
      p {
        margin-bottom: 14px;
        font-size: 14px;
      }
      .grand_total_card_row {
        padding: 0;
      }
      .pax-total-price {
        display: flex;
        justify-content: space-between;
        .pax-type {
          p {
            font-size: 18px;
            margin-bottom: 0;
          }
        }
        .total {
          display: flex;
          p {
            font-size: 18px;
            margin-bottom: 0;
          }
          .amount {
            font-weight: 700;
          }
        }
      }
    }
  }
  .user-details {
    margin-bottom: 15px;
    .heading {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }

    .card-adult-text {
      .pax-heading {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #009966;
      }
    }
    .pax-head-hr hr {
      border: 1px solid #009966;
    }
    .checkout-custom-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;
      margin-bottom: 20px;
      .ant-card-body {
        padding: 0;
      }
      .card-adult-text {
        margin-bottom: 13px;
        @include mobile {
          flex-direction: row;
          flex-wrap: inherit;
        }
        .col-md-3,
        .col-md-9 {
          @include mobile {
            padding: 0px;
          }
        }
      }
      .pax-heading {
        display: flex;
        // justify-content: space-between;
        p {
          font-weight: 800;
          margin-bottom: 0px;
        }
        span {
          font-size: 12px;
          color: #505c75;
          padding: 2px 10px;
        }
      }
      .pax-head-hr {
        hr {
          border-top: 1px solid #bcd5f5;
          max-width: 100%;
        }
      }

      .pax-info-form {
        .form-group {
          margin-bottom: 25px !important;
          @include mobile {
            margin-bottom: 0px !important;
          }
          .plaintext {
            border: none !important;
          }
          input[type="text"] {
            border: solid 1px #bcd5f5 !important;
            // height: 45px;
            @include mobile {
              font-size: 15px;
              height: 45px;
            }
          }
          label {
            color: #505c75;
            @include mobile {
              font-weight: 600;
            }
            span {
              color: red;
              font-size: 18px;
            }
          }
          select {
            border: solid 1px #bcd5f5;
            @include mobile {
              height: 45px;
              font-size: 15px;
            }
          }
          .title-select {
            max-width: 130px;
            border: solid 1px #bcd5f5;
            @include mobile {
              max-width: 100%;
            }
          }
          .dob-select {
            display: flex;

            @include mobile {
              flex-direction: column;
              align-items: flex-start;
            }
            .title-select {
              margin-right: 10px;
              @include mobile {
                margin-bottom: 18px;
                margin-right: 0px;
              }
            }
            input {
              max-width: 130px !important;
              @include mobile {
                max-width: 100%;
              }
            }
          }
          .Country-select {
            max-width: 200px !important;
            @include mobile {
              max-width: 100%;
            }
          }
        }
        .contact-details-text {
          p {
            font-size: 13px;
            color: #969dac;
            @include mobile {
              font-size: 12px;
              margin-top: 10px;
            }
          }
        }
        .register-check {
          display: flex;
          p {
            font-size: 15px;
          }
          span {
            color: #ea1b37;
            font-weight: 700;
          }
        }
      }
      .hr-line {
        margin-top: 0px !important;
        @include mobile {
          display: none;
        }
      }

      .airline-bottom-section {
        .airline-bottom-section-left {
          .trip-timing {
            text-align: center;
            p {
              font-weight: 700;
            }
            .trip-row-mobile {
              @include mobile {
                flex-wrap: nowrap;
                justify-content: flex-start;
              }
              .col-md-2 {
                i {
                  @include mobile {
                    margin-left: -33px;
                  }
                }
              }
              .arrive-time {
                p {
                  @include mobile {
                    margin-left: -38px;
                  }
                }
              }
            }
          }
          .trip-duration {
            display: flex;
            span {
              color: #969dac;
              font-size: 13px;
            }
            p {
              font-size: 14px;
              padding-left: 4px;
            }
          }
        }
        .airline-bottom-section-right {
          text-align: right;
          button {
            background: none;
            color: #007bff;
            font-size: 13px;
            font-weight: 700;
            padding: 6px 16px;
            &:hover {
              background-color: #007bff;
              color: #fff;
            }
          }
        }
      }
    }
    h3 {
      @include mobile {
        margin-bottom: 12px;
      }
    }
  }

  .contactwrapper,
  .paxInfowrapper {
    .ant-form label {
      color: #05004e;
      font-weight: 600;
    }
    .inputbg,
    .ant-select-selector {
      background-color: #f1f2f6 !important;
    }
    .phno {
      .ant-input-group-addon {
        background-color: transparent;
      }
      input {
        background-color: transparent;
      }
    }
  }

  .paxInfowrapper {
    .heading {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .change-flight-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 18px;
    }
    p {
      color: #007bff;
      cursor: pointer;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  .flight-details-card {
    margin-bottom: 20px;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
    .airline-details {
      .airline-details-left {
        .dept_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          flex-wrap: wrap;
          .airline-img {
            img {
              max-width: 35px;
              margin-right: 5px;
            }
            @include xsmobile {
              flex: 0 1 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 5px;
            }
            .name {
              font-weight: 600;
            }
          }
          .airline-dte {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            @include xsmobile {
              justify-content: flex-start !important;
            }

            h5 {
              font-weight: 600;
              margin-bottom: 0;
              font-size: 16px;
              @include mobile {
                font-size: 13px;
              }
            }
            span {
              font-size: 14px;
              color: #969dac;
              margin-left: 10px;
              font-weight: 600;
              @include mobile {
                font-size: 13px;
              }
            }
          }
          .direct-text {
            p {
              margin-bottom: 0;
              font-size: 13px;
              color: #000;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }

        .airline-src-dest {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          p {
            font-weight: 600;
            margin-bottom: 0;
            font-size: 18px;
            @include mobile {
              font-size: 16px;
            }
            @include xsmobile {
              font-size: 14px;
            }
            .code {
              margin-left: 5px;
              font-size: 14px;
              @include mobile {
                font-size: 12px;
              }
              @include xsmobile {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .trip-details {
      .trip-timimg-details {
        ul {
          display: flex;
          li {
            margin-right: 15px;
            font-weight: bold;
          }
        }
      }
      .duration-timing {
        display: flex;
        font-size: 12px;
        @include mobile {
          display: none;
        }
        p {
          margin-bottom: 0;
          color: #969dac;
        }
        span {
          color: #000;
        }
      }
      .details-btn {
        text-align: end;
      }
    }
    .flight-timings {
      .timing_wrapper {
        .travel-time {
          justify-content: center;
        }
      }
    }
  }
  .book-pay-section {
    margin: 25px 0px;
    .ant-card-body {
      padding: 0;
    }
    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;
      .book-pay-tc {
        .ant-checkbox-wrapper {
          font-weight: 600;
        }
      }
      .book-pay-offers {
        display: flex;
        p {
          font-size: 15px;
          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }
      .book-pay-btn {
        height: 50px;
        button {
          background-color: #009966;
          color: #fff;
          border: none;
          font-size: 18px;
          font-weight: 600;
          padding: 10px 20px;
          height: 100%;
          border-radius: 8px;
          &:hover {
            background-color: #005f3f;
          }
          @include mobile {
            font-size: 16px;
          }
        }
        .book-and-content {
          padding-top: 20px;
          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }
  }
}

.payer-radio-group {
  @include mobile {
    flex-wrap: inherit;
  }
}
.contact-number {
  display: flex;
  .dob-select {
    margin-right: 10px;
  }
}
.pax-count-card {
  border: none;

  padding: 0px;

  // padding: 25px 20px;
  // padding-bottom: 0px;
  .accordion {
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    padding: 5px 15px;
    // padding-bottom: 0px;
    @include mobile {
      margin-bottom: 0px;
    }
  }
  .mobile_acc {
    border-bottom: none !important;
    padding: 5px;
  }
  .all-taxes {
    font-size: 12px;
    color: #969dac;
    text-align: right;
  }
  .pax-total-price {
    display: flex;
    justify-content: space-between;

    .pax-type {
      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
    }
    .total {
      display: flex;
      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
      .amount {
        margin-bottom: 0px;
        font-weight: 600;
      }
    }
  }
  .pax-pre-price {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    // padding-bottom: 0px;
    .pax-type {
      p {
        font-size: 16px;
        margin-bottom: 0;
        @include mobile {
          margin-bottom: 0px;
          font-weight: 600;
        }
      }
    }
    .total {
      display: flex;
      i {
        @include mobile {
          font-size: 25px;
          padding-left: 7px;
        }
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .amount {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }
  .service-price {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
    .main-price {
      font-size: 14px;
      padding-left: 6px;
    }
  }

  .pax-count-card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 0px;
  }
}
.pax-count-header-top {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  cursor: pointer;
  .pax-header-left-sec {
    display: flex;
    p {
      padding-left: 6px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
.border-line {
  border-top: solid 1px #ccc !important;
  // margin-top: 15px;
}
.details-modal {
  .details-header {
    p {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
    }
    button {
      font-size: 34px;
    }
  }
  .modal-body {
    padding: 0px 15px;
  }
}

.faresummary_mobile {
  display: none;
  @include mobile {
    display: block;
    margin-top: 23px;
  }
}
/********** Antd Styles *******/
.passenger-form {
  .ant-form-item-label {
    text-align: start;
  }
}
.flight-checkout-contact-form {
  .ant-form-item-label {
    text-align: start;
  }
}
/********** fare summary card **********/
.sticky-card-container {
  // position: sticky;
  // top: 0;
  margin-bottom: 20px;
  .ant-card-body {
    padding: 12px 24px;
  }

  .ant-collapse {
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        background-color: #fff;
        padding-left: 20px;
        padding-right: 0;
        .anticon {
          top: 16px;
          left: 0;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 10px 5px 0 5px !important;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.airline-details-right {
  text-align: right;
  padding-top: 10px;
  p {
    font-weight: 700;
    font-size: 14px;
  }
}

.trip-duration-strip {
  background: #eaebee;
  .trip-time {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    padding-left: 40px;
    i {
      font-size: 20px;
    }
    p {
      margin-bottom: 0px;
      font-size: 15px;
      padding-left: 7px;
    }
  }
}
.flight-details-modal-container {
  .trip-body {
    padding-left: 40px;
    padding-top: 14px;
    .trip-strip {
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 10px;
        left: -13px;
        bottom: 24px;
        background: #f9b975;
        width: 1px;
      }
    }
    .trip-body-details {
      display: flex;
      position: relative;
      &::before {
        display: block;
        background-color: #f9b975;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        position: absolute;
        left: -15px;
        top: 9px;
        content: "";
      }
      p {
        font-size: 15px;
        &:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
  }
  .trip-bottom-duration {
    padding-left: 40px;
    padding-bottom: 20px;
    p {
      margin-bottom: 0px;
      font-size: 13px;
    }
  }
  .ant-modal-body {
    padding: 0 !important;
  }
}
.pricepopup {
  .ant-popover-title {
    font-size: 13px;

    padding: 5px 8px;
  }
  .ant-popover-inner-content {
    padding: 5px 8px;
    .pax-count-acc-body {
      display: flex;
      justify-content: space-between;
      color: #505c75;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.85);
      p {
        margin-bottom: 5px;
      }
    }
  }
}
.gst-details {
  .heading {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.add-gst-text {
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 16px;
    @include mobile {
      font-size: 14px;
    }
  }
}
.add-gst-block {
  .ant-collapse-borderless {
    background-color: white;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: transparent;
  }
}
.promo-card {
  margin-top: 25px;
  @include mobile {
    margin-top: 20px;
  }
  .promo-card-header {
    .name {
      font-size: 18px !important;
    }
  }
  .promo-input {
    .applied {
      color: #e12001;
      font-weight: 600;
    }
    .promo-input-item {
      width: 72%;
      @include mobile {
        width: auto;
      }
    }
  }
  .ant-form {
    button {
      background-color: #009966;
      color: #fff;
      border: none;
      font-size: 16px;
    }
  }
  .promo-input-btn {
    margin-right: 0 !important;
    background-color: #009966;
    color: #fff;
    border: none;
    font-size: 16px;
  }
}
.service-charge {
  margin-top: 25px;
  .service-charge-header {
    h4 {
      margin-bottom: 25px;
      font-size: 23px;
      @include mobile {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  .service-charge-table {
    .ant-table {
      overflow: hidden;
    }
  }
}

.flight-review-row {
  @include mobile {
    flex-wrap: wrap-reverse !important;
  }
}
.passport-dates {
  .ant-picker {
    width: 100%;
  }
}
.discount-price {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  .total {
    display: flex;
    p {
      margin-right: 5px;
    }
  }
}
.flight-cards-details {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2) !important;
  font-family: GT Walsheim, Helvetica, Arial, sans-serif !important;
  .fareTitle {
    font-weight: 600;
  }
  .grand_total_card_row {
    padding: 0;
    p {
      margin-bottom: 14px;
      font-size: 14px;
    }
    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon-question-circle {
        margin: 0 0 14px 5px;
        color: #969dac;
        cursor: pointer;
      }
    }
  }
}
.book-pay-section {
  width: 100% !important;
}
.contacts-value-de .ant-select-selector {
  display: block;
  width: 100%;
  color: rgb(66, 84, 134) !important;
  background-color: rgb(241, 242, 246) !important;

  border: 1px solid transparent !important;

  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease 0s;
}

.contacts-value-de .ant-select-selector:focus {
  color: #495057;
  background-color: rgb(241, 242, 246) !important;

  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.contacts-value-de {
  display: block;
  width: 100%;
  color: rgb(66, 84, 134) !important;
  background-color: rgb(241, 242, 246) !important;

  border: 1px solid transparent !important;
  /* height: 48px; */

  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease 0s;
}

.contacts-value-de:focus {
  color: #495057;
  background-color: rgb(241, 242, 246) !important;

  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media screen and (max-width: 768px) {
  .book-pay-section {
    width: 100%;
  }
}
// .user-details .ant-skeleton-content {
//   border: 1px solid rgba(0, 0, 0, 0.03);
//   box-shadow: 0px 0px 2px 0px #969dac;
// }

.apply-promo-value-code {
  background-color: #e12001;
  color: #fff;
  border: none;
  font-size: 16px;
}
.details-btn button {
  background-color: #e12001 !important;
  color: #fff !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 12px !important;
  line-height: 15px !important;
  border-radius: 8px;
}
.farType-wrapper .refundText {
  padding-left: 13px;
}
