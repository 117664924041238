@import "../../assets/variableStyles.scss";
.footer-block {
  .footer-container {
    padding: 20px 15px 5px 15px;

    .ongo,
    .in-ofc,
    .help-btn {
      p {
        font-size: 14px;
        font-weight: bold;
        color: #777;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      ul {
        li {
          color: #777;
          font-size: 13px !important;
          line-height: 14px;
          font-style: normal;
          margin-bottom: 10px;
        }
      }
    }
    .follow-block {
      p {
        font-size: 18px;
        font-weight: bold;
        color: #777;
        margin-bottom: 0px;
      }
      .socialIcons {
        display: flex;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #777;
          border-radius: 50%;
          margin-right: 10px;
          height: 25px;
          width: 25px;
          cursor: pointer;
          &:hover {
            background-color: #005f3f;
          }
          i {
            color: #005f3f;
          }
        }
      }
    }
  }
}

.footer-lines-new li a {
  color: #666
}
.footer-block {
  background-color: #f5f5f5;
}
.ft-v3-1 {
  border-bottom: 1px solid #CCC;
  color: #666;
  margin-bottom: 10px;
  padding: 0.5rem 0;
  position: relative;
}
.store-icons {
  display: flex;
  justify-content: flex-end;
}
.store-icons p {
  margin-bottom: 0px;
}
.play-store-1 {
  padding-left: 10px;
}
.dwn-heading {
  font-size: 23px;
  color: #666;
  @include mobile {
    font-size: 15px;
  }
}
.media-icons-store {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 50%;
    margin-left: 10px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    &:hover {
      background-color: #ddd;
    }
  }
  i {
    color: #666;
  }
}

.phone-ft-bottom {
  margin-bottom: 0px;
  font-size: 20px;
  color: #009966;
}
.ft-award-22 {
  padding-top: 6px;
}
.text-msg-1 {
  font-size: 1rem;

  color: #666;
}
.left-line-ft {
  padding-left: 30px !important;
  @include mobile {
    padding-left: 0px !important;
  }
}
.ft-award-1 {
  padding-top: 30px;
}
.ft-bottom-start li {
  display: inline;
  padding: 0px 12px 0px 0px;
}
.ft-bottom-start li .fa {
  font-size: 15px;
  background: #ef6a23;
  height: 23px;
  width: 23px;
  line-height: 23px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 13px;
}
.ft-award-2 {
  padding-top: 20px;
}
.we-accept{
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
}
.copyright{
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}