@media screen and (max-width: 768px) {
  .between-flv {
    margin-left: 20px !important;
  }
  .between-flv .ant-skeleton-title {
    margin-left: 60px !important;
  }
  .between-flv .ant-skeleton-paragraph {
    margin-left: 45px !important;
  }
}

.between-flv {
  margin-left: 40px;
}
