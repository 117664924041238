@import "../../../assets/variableStyles.scss";
.hotel-sort-block {
  margin-bottom: 15px;
  .sort-text {
    margin-bottom: 0;
    font-weight: 600;
  }
  .results-sort-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    .flex-wrapper {
      display: flex;
      align-items: center;
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
      .ant-radio-wrapper {
        margin-left: 25px;
        margin-right: 0;
        .price-type {
          span {
            padding-right: 5px;
          }
        }
      }
    }
    .ant-input-group {
      max-width: 330px;
      min-width: 220px;
      .search-type-box {
        max-width: 55px;
      }
      .search-select-box {
        width: calc(100% - 55px);
      }
    }
  }
  @include mobile {
    display: none !important;
  }
}
.cp-sort-by-hotel .ant-select-selector {
  font-weight: 700;
  background: #f7f7f7;
}
.cp-aria-cp {
  display: none;
}
@media screen and (max-width: 768px) {
  .cp-aria-cp {
    display: block;
    text-align: center;
    font-size: 24px;
  }
  .cp-sort-by-hotel .ant-radio-wrapper {
    border-right: 0px solid #ccc !important;
  }
  .cp-sort-by-hotel .anticon-caret-up {
    display: none !important;
  }
  .cp-sort-by-hotel .ant-radio-wrapper-checked {
    border-bottom: 0px solid #87939e !important;
  }
}
