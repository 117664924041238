.admin-shadow {
  .ant-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgb(19, 41, 104 / 20%);
    padding: 20px 20px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .admin-loginbtn {
    font-size: 16px;
    font-weight: 500;
    background-color: #009966 !important;
    color: #fff;
    border: none;
    margin: 0 auto;
    display: block;
    border-radius: 5px;
    margin-top: 10px;
  }
}
