$primary: #e12001;
$secondry: #05004e;

.flighteq {
  .inputbg,
  .ant-select-selector {
    background-color: #f1f2f6 !important;
  }

  .flight-enu {
    background-color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  }
  .flight-enu label {
    line-height: 24px;
    color: #000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .flights-gap label {
    line-height: 24px;
    color: #000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .flights-gap {
    background-color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  }

  .btn-colors {
    .ant-btn {
      background-color: $primary !important;
      border-color: $primary !important;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: auto;
      &:hover {
        background: $secondry !important;
        background-color: $secondry !important;
      }
    }
  }
}

.hideOk {
  .ant-picker-footer {
    display: none;
  }
}
