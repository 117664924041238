@import "../../assets/variableStyles.scss";
$primary: #e12001;
$secondry: #05004e;
.search-block {
  .class-container {
    .ant-radio-wrapper {
      color: #e12001;
      font-weight: bold;
    }
  }
  .class-type {
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    @include mobile {
      padding-left: 11px;
    }
  }

  .search-body {
    // padding: 0 50px;
    //background-color: #fff !important;
    //border-radius: 10px;
    //padding: 8px 25px !important;
    //position: relative;

    //box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    /*  border: 1px solid #05004e; */
    @include mobile {
      padding: 8px 8px !important;
    }
    .search-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .search-inputs {
      width: 100%;
    }
    .search-inputs-btn {
      background-color: #05004e;
      color: #fff;
    }
    .tg-primary-btn {
      background-color: #009966;
      color: #fff;
      border-color: #009966;
      height: 3.4375rem !important;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      &:hover {
        background-color: #005f3f;
        border-color: #005f3f;
        color: #fff;
      }
      &:active {
        background-color: #005f3f;
        border-color: #005f3f;
        color: #fff;
      }
    }

    .from_wrapper {
      position: relative;
      .icon_wrapper {
        position: absolute;
        // height: 100%;
        width: 30px;
        display: flex;
        // align-items: center;
        justify-content: center;
        z-index: 1;
        color: #3b425d !important;
        font-size: 21px;
        left: 7px;
        top: 18px;
      }
      .ant-select-selector {
        // padding: 0 11px 0 30px !important;
        .ant-select-selection-item {
          // line-height: 48px !important;
          color: #313541 !important;
          font-size: 0.875rem !important;
        }
      }
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
    }

    .search-inputs-swap-icon {
      text-align: center;
      color: #e12001;
      .anticon svg {
        font-size: 22px;
        margin-top: 9px;
        @include mobile {
          margin-top: -14px;
        }
      }
    }
    .paxmodal {
      .ant-input {
        cursor: pointer;
        font-size: 16px;
        color: #8d9190 !important;
      }
    }
  }
}
.combined-search-block {
  .search-row {
    align-items: normal !important;
  }
}
.mobile-fbt-tabs .ant-collapse-header {
  display: block;
}

.mobile-fbt-tabs .ant-collapse-header {
  display: block;
  color: #ffffff !important;
  font-size: 17px;
  text-align: end;
  border: 1px solid #e12001;
  margin-left: auto;
  width: 173px;
  border-radius: 5px !important;
  line-height: 15px !important;
  background: #e12001;
}
.mobile-fbt-tabs .ant-collapse-header:hover {
  background-color: #05004e;
  color: #fff;
  border: 1px solid #05004e;
}
.mobile-fbt-tabs .ant-collapse-arrow {
  right: 132px;
  color: #ffffff !important;
  font-size: 17px !important;
  left: auto !important;
}
@media only screen and (max-width: 768px) {
  .mobile-fbt-tabs .ant-collapse-header {
    display: block;
    color: #05004e !important;
    font-size: 17px;
    text-align: start !important;
    border: 0px solid;
    margin-left: 0;
    width: auto;
    border-radius: 0px !important;
    background: transparent;
  }
  .mobile-fbt-tabs .ant-collapse-arrow {
    right: 19px;
    color: #05004e !important;
    font-size: 17px !important;
    left: auto !important;
  }

  .mobile-fbt-tabs .ant-collapse-header:hover {
    background-color: transparent !important;
    color: #05004e !important;
    border: 0px solid #05004e !important;
  }
  // .row-from-background .ant-form-item {
  //   margin-bottom: 11px !important;
  // }
}

.class-container span {
  color: #000000;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
  overflow: visible;
}
.row-from-background .ant-select-selector {
  // font-size: 16px !important;
  border: 1px solid#efefef !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  color: #8d9190;
  // line-height: 48px;
  // text-align: left;

  // caret-color: #a1a9c3;
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  // transition: border 0.15s ease;
}
.from-return-date {
  // font-size: 16px !important;

  border: 1px solid #f1f2f6 !important;
  color: #434343;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px !important;
  // text-align: left;
  // letter-spacing: 0.3px;
  // caret-color: #a1a9c3;
  // transition: border 0.15s ease;
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
}
.adult-age-per {
  // font-size: 16px !important;

  border: 1px solid #f1f2f6 !important;
  color: #434343;

  border-radius: 4px !important;
  // text-align: left;
  // letter-spacing: 0.3px;
  caret-color: #a1a9c3;
  // transition: border 0.15s ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  .passangericon {
    width: 21px;
    height: 17px;
    // filter: invert(43%) sepia(35%) saturate(6781%) hue-rotate(321deg)
    //   brightness(90%) contrast(84%);
  }
}

.search-body {
  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    font-size: 16px;
    color: #313541 !important;
  }
}

.search-body .ant-select-item-option-content {
  font-weight: 400;
}
.search-body .ant-picker-input > input {
  font-size: 16px;
  font-weight: 400;

  color: #313541 !important;
}
.search-body .ant-picker-input > input::placeholder {
  color: #313541 !important;
  font-weight: 400 !important;
}

.search-body {
  .ant-picker-input {
    input {
      order: 2 !important;
    }

    .ant-picker-suffix {
      margin: 0 4px 0 0;
      color: #3b425d !important;
      order: 1 !important;
      font-size: 19px;
    }
  }
}
.ant-select-item-option-content {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // font-size: 12px;
  // font-weight: normal;
  font-weight: 600;
}

.bdpoint {
  .ant-select-item-option-content {
    font-size: 12px;
    font-weight: normal;
    .pointTooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.ant-picker-header-view {
  font-weight: 700;
}
// .row-from-background .ant-select-selection-search-input {
//   height: 38px;
//   font-weight: 400;
//   color: #8d9190 !important;
//   font-size: 18px;
// }

.class-v3-flight {
  text-align: end;
  display: none;
}
.manage-book-flight ul li {
  display: inline;
  margin: 6px 6px;
}
.manage-book-flight ul li a {
  text-align: center;
  margin-right: 6px;
  border-radius: 4px;
  white-space: normal;
  color: #555;
  border: 1px solid #dedede;
  padding: 5px 9px;
  display: none;
}
.first-one-li a {
  font-weight: 600;
  color: #fff !important;
  border: 1px solid #ed1c24 !important;
  background: #ed1c24 !important;
  padding: 5px 9px !important;
  border-radius: 4px;
  margin: 0px 3px;
}
.sub-v3-menus a {
  text-align: center;
  margin-right: 6px;
  border-radius: 4px;
  white-space: normal;
  color: #555;
  border: 1px solid #dedede;
  padding: 5px 9px;
}
.manage-book-flight {
  margin-bottom: 22px;
  margin-top: 10px;
  display: none;
}
.manage-book-flight p {
  margin-bottom: 0px;
}
.manage-book-flight p {
  display: inline;
}
p.sub-v3-menus {
  margin: 0px 3px;
}
.airpot_names_dropdown {
  min-width: 400px !important;
  font-size: 13px;
}
@media screen and (max-width: 768px) {
  .manage-book-flight {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .first-one-li a {
    margin: 0px 0px;
    padding: 2px 2px !important;
    font-size: 12px;
  }
  .sub-v3-menus a {
    padding: 2px 2px !important;
    font-size: 12px;
  }
  p.sub-v3-menus {
    line-height: 29px;
  }
  .manage-book-flight p {
    display: inline-block;
  }
  .airpot_names_dropdown {
    min-width: initial !important;
  }
}

.overall-tabs-v3 .ant-tabs-content-holder {
  background: rgba(9, 33, 64, 0.75);
  border-radius: 0.25rem;
  display: block;
  padding: 20px 10px 10px 10px;
  z-index: 10;
  //position: absolute;
  width: 100%;
  max-width: 1200px;
  margin-top: 0px;
}
.overall-tabs-v3 .ant-tabs-nav {
  margin: 0 0 0px 0 !important;
  z-index: 12;
}
.overall-tabs-v3 .ant-tabs-tab-active {
  background: rgba(9, 33, 64, 0.75);
  color: #fff;
  text-decoration: none;
}
.overall-tabs-v3 .ant-tabs-tab-btn {
  color: #ffffff !important;
  @include mobile {
    font-size: 14px !important;
  }
}
.overall-tabs-v3 .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}
.overall-tabs-v3 .ant-tabs-tab {
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  padding: 12px 12px 12px 12px;
  text-align: center;
  text-transform: uppercase;
  @include mobile {
    font-weight: 700;
    padding: 12px 2px 12px 2px;

    font-size: 14px !important;
    margin: 0 14px 0 0 !important;
  }
}
.overall-tabs-v3 .ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0 !important;
}
.overall-tabs-v3 .ant-select-selector {
  font-size: 14px;
  font-weight: 700;
  height: 3.4375rem !important;

  padding: 9px 35px !important;
}
.overall-tabs-v3 input {
  height: 3.4375rem !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.overall-tabs-v3 .ant-picker-large {
  height: 3.4375rem !important;
}
.overall-tabs-v3 .ant-input-affix-wrapper-lg {
  height: 3.4375rem !important;
  padding: 0px 10px !important;
}
.overall-tabs-v3 .anticon-environment {
  color: #3b425d;
}
.overall-tabs-v3 .ant-input-lg {
  background: transparent;
}
.overall-tabs-v3 .ant-select-selection-placeholder {
  font-size: 0.875rem;
  font-weight: 700;
  color: #313541;
}
.top-line .ant-select-selector {
  height: auto !important;
  padding: 0px 10px !important;
}
.top-line input {
  height: auto !important;
}
.class-container {
  margin-bottom: 8px;
}
.v3-round-icon {
  position: absolute;
  top: 23.4%;
  z-index: 9;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  /* left: 0.5%; */
  right: -13px;
}
.v3-round-icon:hover {
  transform: rotate(1turn);
}
.bdr-icon-f span {
  color: #ffffff !important;
  font-size: 12px !important;
}
.pre-airline .ant-input {
  background: none;
  border-radius: none;
  border: none;
  border-bottom: 0.0625rem solid #fff;
  color: #fff;
  height: 1.875rem;
  padding: 0.5rem 1.5rem;
  width: 100%;
}
.v3-place-icon .anticon-search {
  position: absolute;
  top: 14px;
  color: #ffffff;
  font-size: 14px;
}
.pre-airline .ant-form-item {
  margin-bottom: 5px;
}
.flv-switch {
  color: #ffffff;
  padding-left: 6px;
}
.pdr-top-1 {
  padding-top: 10px;
}
.v3-air-icon input {
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .v3-round-icon {
    display: none;
  }
  .paxpopup .ant-popover-arrow {
    display: none;
  }
  .bg-v3-ban {
    padding: 0rem !important;
  }
  .paxpopup .pax-modal .pax-modal-wrapper li {
    display: flex;
    border-bottom: 1px solid #dcdee3;
  }
  .paxpopup .pax-modal .pax-modal-wrapper {
    display: block;
  }
  .paxpopup .pax-modal .pax-modal-wrapper li .pax-label {
    text-align: left;
  }
  .overall-tabs-v3 .ant-tabs-content-holder {
    width: 100% !important;
    position: relative;
    margin-top: 0px;
    padding: 5px 5px 5px 5px;
    right: 0px;
    // height: 580px;
  }
  .overall-tabs-v3 .tg-primary-btn-h3 {
    width: 100%;
    margin-top: 7px;
  }
  .pax-ready-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.paxpopup {
  width: 70%;
  @include mobile {
    width: 84%;
    left: 29px !important;
  }
}
.pax-ready-btn {
  border-radius: 3px;
  background: #fff;
  padding: 6px;
  color: #0775e2;
  font-size: 11px;
  font-weight: 700;
  border: 2px solid #0775e2;
  width: 35%;
  margin-left: auto;
  display: block;
  // margin-right: 48px;
  margin-top: 10px;
}

/*hotel--part-css*/
.overall-tabs-v3 .heading_text {
  display: none;
}
.overall-tabs-v3 .background_banner {
  background: url();
}
.overall-tabs-v3 .hotels_search_box {
  padding: 0px 0px !important;
}
.overall-tabs-v3 .bus-search-header {
  display: none;
}
.overall-tabs-v3 .hotel-room-res-v3 {
  background-color: #fff;
  padding: 0px 40px;
}
.overall-tabs-v3 .ant-form-item {
  margin-bottom: 10px;
}
.start-hotel-vale .ant-select-selector {
  height: auto !important;
  padding: 0px 10px !important;
  background-color: transparent !important;
}
.start-hotel-vale {
  padding-left: 15px;
}
.start-hotel-vale .ant-select-selection-item {
  font-size: 11px;
  color: #fff !important;
}
.start-hotel-vale .ant-select-arrow {
  color: #ffffff !important;
}
.swap-v33-one {
  position: relative;
}
.overall-tabs-v3 .new-bus-top {
  display: none;
}
.flight-arrow-v2 {
  left: 98.2% !important;
  @include mobile {
    left: 19.2% !important;
  }
}
.fliht-modal-left {
  left: -11px !important;
  margin-top: 2px !important;
  @include mobile {
    left: 0px !important;
  }
}
