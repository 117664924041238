$primary: #e12001;
$secondry: #05004e;
.unauthorized {
  img {
    max-width: 250px;
  }
  .ant-result-icon {
    margin-bottom: 0;
  }
  .ant-result-title {
    color: #ff0303;
    font-weight: 800;
    font-size: 42px;
  }
  .ant-result-subtitle {
    font-weight: 600;
  }
  .ant-btn {
    background-color: #009966 !important;
    border-color: #009966 !important;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    &:hover {
      background: $secondry !important;
      background-color: $secondry !important;
    }
  }
}
.error-404 {
  .ant-btn {
    background-color: #009966 !important;
    border-color: #009966 !important;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    &:hover {
      background: #009966 !important;
      background-color: #005f3f !important;
    }
  }
}
.no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 28px;
  padding-top: 28px;
}
.backto-homebtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
}
.text-results-found {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}
