.busFareDetail_wrapper {
  .price_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
  .total_price {
    font-size: 18px;
    font-weight: 600;
    p {
      margin-bottom: 0;
    }
  }
  margin-bottom: 25px;
}

.buspromo_wrapper {
  .name {
    font-size: 18px;
    font-weight: 600;
  }
  .applied {
    color: #d5436a;
    font-weight: 600;
  }
  .ant-form {
    button {
      background-color: #009966;
      color: #fff;
      border: none;
      font-size: 16px;
    }
  }
}
