@import "../../assets/variableStyles.scss";

.promocodes {
  .offercontainer {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }
  .deals-details-wrapper {
    //background-image: url("../../assets/images/deals_details_bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
    @include mobile {
      background-position: center;
    }
    .bread-crumb-block {
      position: absolute;
      top: 0;
      ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
        background-color: transparent;
      }
      ul.breadcrumb li {
        display: inline;
        font-size: 12px;
      }
      ul.breadcrumb li + li:before {
        padding: 8px;
        color: #dcdee3;
        content: "/\00a0";
      }
      ul.breadcrumb li a {
        color: #dcdee3;
        text-decoration: none;
      }
      ul.breadcrumb li a:hover {
        color: #dcdee3;
        text-decoration: underline;
      }
      ul.breadcrumb li span {
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .main-text {
    h2 {
      color: #000;
      padding: 16px 0;
      margin: 0;
      font-size: 22px;
      text-align: center;
    }
  }
}
.promocode-wrapper {
  background-color: #f6f7f8;
  .promocodes-section {
    padding: 25px;
    .coupon-card {
      .images-slider {
        border-color: #ccc;
        border-style: solid;
        border-width: 1px 1px 0 1px;
        border-radius: 35px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .coupon-content {
        border: solid 1px #ccc;
        margin-top: -8px;
        padding: 10px;
        border-radius: 35px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background-color: #fff;
        .coupon-code {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .codes {
            flex: 1;
            p {
              font-size: 14px;
              margin-bottom: 0px;
            }
            h2 {
              font-size: 20px;
              margin-bottom: 0px;
              @include mobile {
                font-size: 24px;
              }
            }
          }
          .copy-code {
            cursor: pointer;
            padding: 5px 10px;
            background-color: #eeffdd;
            color: #417505;
            border: 2px dotted red;
            p {
              margin-bottom: 0;
            }
          }
        }
        .coupon-expiry-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .countdown {
            p {
              font-size: 11px;
            }
          }
          .expiry-date {
            color: red;
          }
          .more-details {
            color: blue;
            display: none;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.slick-slide {
  // height: 238px;
  .slide-img {
    width: 100%;
    height: 150px;
    // border: dotted 4px red;
    border-radius: 35px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.main-text p {
  text-align: center;
  font-size: 14px;
  color: #525252;
  margin-bottom: 40px;
}
