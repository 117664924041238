@import "../../../assets//variableStyles.scss";
.hotel-preview-container {
  .form-body {
    background-color: #eaebee;
    .form-body-container {
      max-width: 1280px;
      margin: auto;

      .form-body-left-block {
        .form-body-left-content {
          .preview-pax-details {
            margin-top: 15px;
            border-radius: 8px;
          }
          .payment-container {
            margin-top: 15px;
            .heading {
              margin-bottom: 12px;
            }
          }
        }
      }

      .form-body-right-block {
        .promo-card {
          margin: 0;
        }
        .hotel-review-fare-details {
          background-color: transparent;
          border-radius: 8px;
          margin-bottom: 5px;
          .accordion {
            .cards {
              background-color: transparent;
              .card-header {
                background-color: transparent;
              }
            }
          }
        }
        .hotel-review-room-details {
          margin-bottom: 5px;
        }
        .grand-total-card {
          .ant-card-body {
            padding: 15px;
            .promo_added_fee {
              padding: 8px 0;
            }
            .total-fare {
              padding: 8px 0;
            }
          }
        }
        .promo-card {
          margin-top: 5px;
        }
        .book-pay-section {
          margin: 25px 0px;
          .ant-card-body {
            padding: 0;
          }
          .book-pay-card {
            padding: 16px 24px;
            box-shadow: 0 2px 2px 0 #dcdee3;
            border: none;
            @include mobile {
              padding: 16px;
            }
            .book-pay-tc {
              display: flex;
              span {
                color: red;
              }
              p {
                margin-bottom: 0;
              }
            }
            .book-pay-offers {
              display: flex;
              p {
                font-size: 15px;
                a {
                  &:hover {
                    color: #007bff;
                  }
                }
              }
            }
            .book-pay-btn {
              height: 50px;
              button {
                background-color: #009966;
                color: #fff;
                border: none;
                font-size: 21px;
                font-weight: 600;
                padding: 10px 31px;
                height: 100%;
                @include mobile {
                  font-size: 16px;
                  padding: 10px 20px;
                }
              }
              .book-and-content {
                padding-top: 20px;
                p {
                  font-size: 15px;
                  color: #969dac;
                }
              }
            }
          }
        }
      }
    }
  }
}
.hotel-fair-box {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 7px;
}
