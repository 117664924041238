.profile-btn {
  color: #ffffff !important;
  border-color: #e12001 !important;
  background-color: #e12001 !important;
  border-radius: 50px;
}
.add-anver {
  float: right;
}
.profile-bg {
  background-color: #f6f7fb;
}

#profile {
  max-width: 1140px;
  margin: auto;
  padding: 20px 0px;
}

.profile_pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #profile {
    margin-top: 25px;
  }
  .min-width-pf {
    display: none;
  }
}
@media only screen and (min-width: 767px) {
  .min-width-pf {
    display: block;
    margin-top: 8%;
  }
  .max-width-ns {
    display: none;
  }
}

.profile-value-de-retrive {
  width: 100%;
  color: #425486 !important;
  background-color: #f1f2f6 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  height: 48px;
  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease 0s;
}
.profile-value-de-retrive:focus {
  border-color: transparent !important;
}
.select-profile .ant-select-selector {
  width: 100%;
  color: #425486 !important;
  background-color: #f1f2f6 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  height: 48px !important;
  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease 0s;
}
.booking-email-pp {
  font-size: 16px;
  line-height: 20px;
  color: #425486;
  letter-spacing: 0;
  margin: 0;
  padding-bottom: 20px;
}
.trip-advice-hl {
  color: #05004e;
  font-size: 22px;
  line-height: 48px;
  font-weight: 700;
}
.profile_pic .ant-upload-list {
  margin-bottom: 33px;
}
