@import "../../assets/variableStyles.scss";
#banner {
  // height: 28em;
  // background-image: url("../../assets/images/hero_image.jpg");
  // background-repeat: no-repeat;
  // background-blend-mode: normal;
  // background-size: cover;
  // margin-top: -67px;
  // padding-top: 67px;
  // text-align: center;
  .carousel {
    margin-top: -68px;
    height: 31em;
    @include mobile {
      display: none;
    }
    .carousel-inner {
      height: 100%;
    }
  }
  // @media (max-width:768px) {
  //   height: 100%;
  //   background-color: #00164d;
  //   background-image: none;
  // }
  .banner-heading {
    max-width: 850px;
    margin: auto;
    padding: 40px 50px;
  }
}
