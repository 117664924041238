.flight_Ticket {
  .Container {
    width: 100%;
    margin: 0 auto;
  }
  .termCon_wrapper {
    padding: 0 10px;
    text-align: justify;
    font-size: 11px;
  }
  .font-family {
    font-family: Verdana, Geneva, sans-serif;
  }
  .fW600 {
    font-weight: 600;
  }
  .fW300 {
    font-weight: 300;
  }
  .fS12p {
    font-size: 12px;
  }
  .fS13p {
    font-size: 13px;
  }
  .fS11p {
    font-size: 11px;
  }
  .border {
    border: 0;
  }
  .mb0 {
    margin-bottom: 0;
  }
  .mt0 {
    margin-top: 0;
  }
  .pd {
    padding: 0px 6px;
  }
  .ptb6p {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .table-Border {
    border: 1px solid #333;
  }
  .blueColor {
    color: #01367a;
  }
  .whtColor {
    color: #fff;
  }
  .background-color {
    background-color: #02456d;
    color: #ffffff;
  }
  .txtAl-r {
    text-align: right;
  }
  .border-top {
    border-top: 1px solid #333;
  }
  .border-bottom {
    border-bottom: 1px solid #000;
  }
  .border-left {
    border-left: 1px solid #333;
  }
  .text-align {
    text-align: center;
  }
  .align-r {
    text-align: right;
  }
  .align-l {
    text-align: left;
  }
  .align-c {
    text-align: center;
  }
}
