.payicons {
    background: url('./images/payicons.gif');
    width: 48px; height: 30px;
    display: inline-block;
    box-shadow: 0 0 0 1px #999;
    border-radius: 4px;
    &.amex{
        background-position: 0px -0px;
    }
    &.diners {
        background-position: -48px -0px;
    }
    &.master{
        background-position: -96px -0px;
    }
    &.rupay{
        background-position: -144px -0px;
    }
    &.visa{
        background-position: -192px -0px;
    }
    &.upi{
        width: 90px;
        background-position: -240px -0px;
    }
    &.nb{
        width: 90px;
        background-position: -330px -0px;
    }
}
