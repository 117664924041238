.contact_wrapper {
  padding: 10px 0;
  width: 100%;
  .ant-divider-inner-text {
    color: #444;
    font-weight: 600;
  }
  .inner_wrapper {
    padding: 8px;
    .contact-boxshadow {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
    }
    .ant-form {
      label {
        color: #444;
        font-weight: 600;
      }
      .inputbg,
      .ant-select-selector {
        background-color: #f1f2f6 !important;
      }
      .phno {
        .ant-input-group-addon {
          background-color: transparent;
        }
        input {
          background-color: transparent;
        }
      }
      .btn_wrapper {
        text-align: center;
        button {
          font-size: 16px;
          font-weight: 500;
          background-color: #009966 !important;
          color: #fff;
          border: none;
          margin: 15px 0;
          &:hover {
            background-color: #005f3f !important;
          }
        }
      }
    }
    .contact_header {
      text-align: center;
      margin-bottom: 16px;
      h2 {
        font-size: 24px;
      }
    }
    .dateStyle {
      width: 100%;
    }
  }

  .comAddr {
    display: flex;
    align-items: center;

    margin-bottom: 5px;
  }
  .heading {
    color: #555;
  }
}
