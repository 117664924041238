@import "../../../../assets/variableStyles.scss";
/********** Passenger Details Card ***************/
.passenger-details-container {
  // .passenger-details-header {
  //     h4 {
  //         font-size: 23px;
  //         margin-bottom: 12px;
  //         @include mobile {
  //             font-size: 16px;
  //         }
  //     }
  // }
  .passenger-details-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
  }
  .passenger-info {
    thead {
      white-space: nowrap;
    }
  }
}
