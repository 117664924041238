@import "../../../assets/variableStyles.scss";
.hotel-checkout {
  .checkout-nav {
    background-color: #00164d;
    .checkout-container {
      .pax-head-hr {
        hr {
          border: 1px solid #009966;
        }
      }
      max-width: 1280px;
      margin: auto;
      padding: 20px 16px;
      .checkout-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .logo {
          h4 {
            color: #fff;
            margin-bottom: 0;
          }
        }
      }
      .checkout-links {
        ul {
          margin-bottom: 0;
          display: flex;
          flex-wrap: wrap;
          li {
            color: #fff;
            display: flex;
            align-items: center;
            margin: 0 15px;
            i {
              margin-right: 5px;
            }
            span {
              border: 1px solid #fff;
              border-radius: 50%;
              font-size: 11px;
              height: 20px;
              width: 20px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-right: 15px;
            }
            &:nth-child(3) {
              margin-left: -15px;
              &:before {
                content: "";
                background-color: #334a69;
                height: 1px;
                width: 25px;
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
  }
  .form-header {
    background-color: #002172;
    .form-header-container {
      max-width: 1140px;
      padding: 0 15px;
      margin: auto;
      .form-header-text {
        padding: 25px 0;
        h2 {
          color: #fff;
          font-size: 24px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
    }
  }
  .form-body {
    background-color: #eaebee;
    .form-body-container {
      max-width: 1140px;
      padding: 0 15px;
      margin: auto;

      .form-body-left-block {
        .form-body-left-content {
          .form-body-login-content {
            background-color: #fff;
            padding: 15px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 2px 5px #ccc;
            .form-body-login-text {
              p {
                margin-bottom: 0;
              }
            }
            .form-body-login-btns {
              button {
                background-color: transparent;
                border: 1px solid #0775e2;
                padding: 7px 15px;
                border-radius: 3px;
                margin-right: 10px;
                font-size: 14px;
                &:last-child {
                  margin-right: 0;
                }
                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 5px;
                }
                span {
                  vertical-align: middle;
                }
                i {
                  font-size: 20px;
                  margin-right: 5px;
                  vertical-align: middle;
                }
              }
            }
          }

          .form-body-room-content {
            margin: 30px 0;
            .room-header {
              display: flex;
              align-items: flex-end;
              margin-bottom: 15px;
              .room-title {
                h1 {
                  font-size: 24px;
                  font-weight: 400;
                  color: #1c2b39;
                }
              }
              .room-para {
                p {
                  font-size: 14px;
                  font-weight: 100;
                  margin-bottom: 10px;
                  margin-left: 5px;
                  color: #505c75;
                }
              }
            }
            .free-cancellation-text {
              span {
                border-bottom: 1px dotted;
                cursor: pointer;
                color: #1da023;
              }
            }
            .room-internal-form {
              background-color: #fff;
              border-radius: 8px;
              .room-body {
                padding: 15px;
                .room-body-header {
                  span {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    color: #1c2b39;
                    &:after {
                      content: "";
                      height: 1px;
                      background-color: #bcd5f5;
                      position: absolute;
                      width: 475px;
                      top: 50%;
                      margin-left: 35px;
                      left: 100%;
                      @media (max-width: 768px) {
                        display: none;
                      }
                    }
                  }
                }
                .room-body-form {
                  margin-top: 20px;
                  margin-bottom: 10px;
                  label {
                    font-size: 14px;
                    color: #505c75;
                    font-weight: 600;
                  }
                  .form-bed-type {
                    margin-bottom: 0;
                  }
                }
              }
              .room-body-footer {
                p {
                  border-top: 1px solid #505c75;
                  padding: 15px;
                  margin-bottom: 0;
                  font-size: 13px;
                  color: #505c75;
                  i {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          .form-body-contact {
            margin: 30px 0;
            .contact-header {
              display: flex;
              align-items: flex-end;
              margin-bottom: 15px;
              .contact-title {
                h1 {
                  font-size: 24px;
                  font-weight: 400;
                  color: #1c2b39;
                }
              }
            }
            .contact-internal-form {
              background-color: #fff;
              border-radius: 8px;
              .contact-body {
                padding: 15px;
                .contact-header {
                  span {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    color: #1c2b39;
                    &:after {
                      content: "";
                      height: 1px;
                      background-color: #bcd5f5;
                      position: absolute;
                      width: 475px;
                      top: 50%;
                      margin-left: 35px;
                      left: 100%;
                    }
                  }
                }
                .contact-form {
                  margin-top: 20px;
                  margin-bottom: 10px;
                  label {
                    font-size: 14px;
                    color: #505c75;
                    font-weight: 600;
                  }
                  .form-bed-type {
                    margin-bottom: 0;
                  }
                  .ant-input-group-wrapper {
                    .ant-input-group-addon {
                      width: 35%;
                      padding: 0;
                      .ant-select-selector {
                        background-color: inherit !important;
                      }
                    }
                  }
                }
                .contact-disclaimer {
                  p {
                    margin-bottom: 0;
                    font-size: 12px;
                    color: #969dac;
                  }
                }
              }
              .contact-footer {
                padding: 15px;
                margin-bottom: 0;
                font-size: 13px;
                color: #505c75;
                i {
                  margin-right: 10px;
                }
                .contact-checkbox {
                  display: flex;
                  align-items: center;
                  label {
                    margin-left: 5px;
                    margin-bottom: 0;
                    font-size: 14px;
                    span {
                      font-weight: 600;
                      color: #0775e2;
                    }
                  }
                }
                .bookandpay-btn {
                  button {
                    font-size: 18px;
                    font-weight: 600;
                    background-color: #eb5196;
                    color: #fff;
                    border: none;
                    height: auto;
                    padding: 10px 30px;
                    border-radius: 3px;
                    &:hover {
                      background-color: #eb5196;
                    }
                  }
                }
              }
            }
          }
          .form-important-info {
            margin: 30px 0;
            .contact-header {
              display: flex;
              align-items: flex-end;
              margin-bottom: 15px;
              .contact-title {
                h1 {
                  font-size: 24px;
                  font-weight: 400;
                  color: #1c2b39;
                }
              }
            }
            .contact-internal-form {
              background-color: #fff;
              border-radius: 5px;
              .contact-body {
                padding: 15px;
                .contact-header {
                  span {
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;
                    color: #1c2b39;
                    &:after {
                      content: "";
                      height: 1px;
                      background-color: #bcd5f5;
                      position: absolute;
                      width: 475px;
                      top: 50%;
                      margin-left: 35px;
                      left: 100%;
                    }
                  }
                }
                .contact-form {
                  margin-top: 20px;
                  margin-bottom: 10px;
                  label {
                    font-size: 14px;
                    color: #505c75;
                  }
                  .form-bed-type {
                    margin-bottom: 0;
                  }
                }
                .contact-disclaimer {
                  p {
                    margin-bottom: 0;
                    font-size: 12px;
                    color: #969dac;
                  }
                }
              }
              .contact-footer {
                padding: 15px;
                margin-bottom: 0;
                font-size: 13px;
                color: #505c75;
                i {
                  margin-right: 10px;
                }
                .contact-checkbox {
                  display: flex;
                  align-items: center;
                  label {
                    margin-left: 5px;
                    margin-bottom: 0;
                    font-size: 14px;
                    span {
                      font-weight: 600;
                      color: #0775e2;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .form-body-right-block {
        .price-form-right {
          // position: sticky;
          // top: 0;
          .hotel-details-box {
            margin-bottom: 5px;
          }
          .promo-card {
            margin-top: 5px;
          }
          .grand-total-card {
            .ant-card-body {
              padding: 15px;
              .promo_added_fee {
                padding: 8px 0;
              }
              .total-fare {
                padding: 8px 0;
              }
            }
          }
        }
        .form-body-right-content {
          background-color: transparent;
          border-radius: 8px;
          margin-bottom: 5px;
          .hotel-fair-box {
            background-color: #ffffff;
            border-radius: 8px;
            margin-bottom: 7px;
            .accordion {
              .cards {
                background-color: transparent;
                .card-header {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    .book-pay-section {
      margin: 25px 0px;
      .ant-card-body {
        padding: 0;
      }
      .book-pay-card {
        padding: 16px 24px;
        box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;
        @include mobile {
          padding: 16px;
        }
        .book-pay-tc {
          display: flex;
          span {
            color: #000;
          }
          p {
            margin-bottom: 0;
          }
        }
        .book-pay-offers {
          display: flex;
          p {
            font-size: 15px;
            a {
              &:hover {
                color: #007bff;
              }
            }
          }
        }
        .book-pay-btn {
          height: 50px;
          button {
            background-color: #009966;
            color: #fff;
            border: none;
            font-size: 21px;
            font-weight: 600;
            padding: 10px 31px;
            height: 100%;
            @include mobile {
              font-size: 16px;
              padding: 10px 20px;
            }
          }
          .book-and-content {
            padding-top: 20px;
            p {
              font-size: 15px;
              color: #969dac;
            }
          }
        }
      }
    }
  }
}
/****** Styles *****/
.hotel-checkout-mt {
  margin-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .form-body-row {
    display: block !important;
  }
  .form-body-left-block {
    max-width: 100% !important;
  }
  .mobile-diretion-part {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .mobile-diretion-part .ant-form-item {
    margin-bottom: 0px;
  }
}

/****** Styles***17-08-2021*****/

.hotel-div-checkout .hotel-details-container {
  //box-shadow: none;
  //padding: 0px 0px 18px;
  margin-bottom: 22px;
}
// .hotel-div-checkout .hotel-details-img-name{
//   float: right;
// }
.hotel-div-checkout .hotel-stay-date-row {
  display: flex;
}
.hotel-collaspe-spin {
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 0px solid #d9d9d9;
  border-radius: 8px;
}
.hotel-collaspe-spin .ant-collapse-header {
  color: #000;
  font-size: 15px;
  font-weight: 900;
}
.guests-child-count .ant-form-item {
  margin-bottom: 0px;
}
.guests-child-count .room-body-form {
  margin-top: 0px !important;
}
.contact-spin-details {
  margin: 0px 0px !important;
}
.contact-spin-details .ant-form-item {
  margin-bottom: 18px;
}
.contact-spin-details .contact-form {
  margin-top: 0px !important;
}
.guestsType {
  font-size: 15px;
  font-weight: 700;
  margin: 5px 0;
}
.room-title {
  font-size: 18px;
  font-weight: 400;
  color: #1c2b39;
}
.price-hotel-main {
  padding-top: 16px;
}
.booking-summary-div {
  padding-top: 20px;
  font-size: 20px;
  background-image: linear-gradient(to right, #009966, #005f3f);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: #fff;
  background-image: -moz-linear-gradient(left, #009966, #005f3f);
  background-image: -ms-linear-gradient(left, #009966, #005f3f);
  background-image: -o-linear-gradient(left, #009966, #005f3f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.booking-summary-div-gu {
  font-size: 20px !important;
  background-image: linear-gradient(to right, #009966, #005f3f);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: #fff;
  background-image: -moz-linear-gradient(left, #009966, #005f3f);
  background-image: -ms-linear-gradient(left, #009966, #005f3f);
  background-image: -o-linear-gradient(left, #009966, #005f3f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold !important;
}
.hotel-div-checkout .image-details {
  max-width: 210px !important;
}
.guests-child-count .ant-select-selection-item {
  line-height: 40px !important;
}
.contact-internal-form .ant-select-selection-item {
  line-height: 40px !important;
}
.contact-internal-form input {
  height: 40px !important;
}
@media only screen and (max-width: 768px) {
  .hotel-div-checkout .image-details {
    max-width: 100% !important;
  }
}
.cp-container-hotel {
  max-width: 1180px !important;
}
.right-block-cp {
  min-width: 25% !important;
}
.guests-child-count input {
  border: 1px solid #bebebe;
  border-radius: 4px;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: 0;
  padding: 0 20px 0 10px;
  width: 100%;
}
.guests-child-count .ant-select-selector {
  border: 1px solid #bebebe !important;
  border-radius: 4px !important;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  height: 40px !important;
  outline: 0;
  padding: 0 20px 0 10px;
  width: 100%;
}
.contact-spin-details input {
  border: 1px solid #bebebe;
  border-radius: 4px;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: 0;
  padding: 0 20px 0 10px;
  width: 100%;
}
.contact-spin-details .ant-select-selector {
  border: 1px solid #bebebe !important;
  border-radius: 4px !important;
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  height: 40px !important;
  outline: 0;
  padding: 0 20px 0 10px;
  width: 100%;
}
.cp-review-book {
  padding-top: 20px;
}
.contact-cp-close {
  margin-bottom: 16px;
}
.cp-note-imp {
  background-color: #fffcc7;
  padding: 5px;
}
.cp-info-note {
  padding-top: 10px;
  font-size: 15px;
}
.promo-card {
  margin-top: 25px;
  @include mobile {
    margin-top: 20px;
  }
  .promo-card-header {
    .name {
      font-size: 18px !important;
    }
  }
  .promo-input {
    .applied {
      color: #009966;
      font-weight: 600;
    }
    .promo-input-item {
      width: 70%;
      @include mobile {
        width: auto;
      }
    }
  }
  .ant-form {
    button {
      background-color: #009966;
      color: #fff;
      border: none;
      font-size: 16px;
    }
  }
  .promo-input-btn {
    margin-right: 0 !important;
    background-color: #009966;
    color: #fff;
    border: none;
    font-size: 16px;
  }
}
.guest-details-form {
  .ant-card-body {
    padding: 1rem;
  }
}
