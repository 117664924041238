.reset-button-fst {
  justify-content: flex-start !important;
  float: left;
}
.email-from-book-reports {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17%;
  height: 180px;
  width: 180px;
}

.tabs_Container {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn {
    color: #05004e;
  }
  .buttons_wrapper {
    button {
      margin: 0 10px;
      background-color: #009966;
      border-color: #009966;
      color: #fff;
      // :hover {
      //   background-color: #05004e;
      //   border-color: #05004e;
      // }
      // :focus {
      //   background-color: #05004e;
      //   border-color: #05004e;
      // }
    }
    .active {
      background-color: #005f3f;
      border-color: #005f3f;
    }
  }
  .tabs_body {
    .results_wrapper {
      .ant-card-body {
        padding: 24px 10px;
      }
    }
  }
}
