.deals-v3-1 {
  margin: 0 0.7rem;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 10px 10px;
  max-width: 17.125rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.deals-v3-card p {
  margin-bottom: 0px;
  color: #009966;
  font-size: 15px;
  font-weight: bold;
}
.deals-v3-card span {
  font-size: 0.75rem;
  line-height: 0.5rem;
  color: #777a83;
}
.deals-v3-card-1 p {
  font-size: 0.875rem;
  line-height: 0.5rem;
  margin-bottom: 0px;
  color: #444;
}
.deals-v3-card-1 span {
  color: #444;
}
.tags-v3-1 {
  position: absolute;
  top: -0.73rem;
  left: 0.6rem;
  background: #009966;
  height: 1.563rem;
  width: 1.563rem;
  border-radius: 20px;
  line-height: 1.563rem !important;
  text-align: center;
  color: #fff;
}
.trip-found-v3 {
  font-size: 22px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}
.deals-v3-card-1 {
  padding-top: 10px;
}
.deals-range-v1 {
  width: 100%;
  margin-top: 0.2rem;
}
.inline-v1 {
  border-right: 0.3rem solid transparent;
  border-bottom: 0;
  border-left: 0.3rem solid transparent;
  display: inline-block;
  height: 0;
  position: absolute;
  top: 8px;
  width: 0;
}
.range-1 {
  border-top: 0.4rem solid #389e0d;
  right: 0;
  left: 3.3rem;
}
.inline-v2 {
  background: #008a04;
}
.range-2 {
  display: inline-block;
  height: 0.25rem;
  border-radius: 0.188rem;
  width: 33.33%;
}
.inline-v3 {
  background: #fbbc02;
}
.inline-v4 {
  background: #ea4335;
}
.calulate-p {
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
  color: #444;
}
.directly-flight-ofc-deals {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0 15px;
}
.icon-post-v2 {
  position: absolute;
  right: 0px;
  top: -6px;
}
.icon-post-v2 .fa {
  font-size: 22px;
  color: #009966;
}

.item-delas-cols {
  max-width: 26.3% !important;
}
.item-delas-v3 {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .item-delas-cols {
    max-width: 100% !important;
    margin-bottom: 29px;
  }
  .deals-v3-1 {
    max-width: 100% !important;
  }
  .trip-found-v3 {
    color: #444 !important;
    font-size: 16px;
  }
}
