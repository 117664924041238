@import "../../assets/variableStyles.scss";
.combined-results-container {
  background-color: #fff;
  #stickyLogo {
    display: none;
    @include mobile {
      display: none !important;
    }
  }

  .combined-navbar-header {
    position: relative !important;
  }
  .ant-card-bordered {
    background-color: rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px;

    border: 0px solid #f0f0f0 !important;
  }

  .train-filters-block {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
  .clear-text {
    font-size: 13px;
    font-weight: 600;
    color: #0775e2 !important;
  }
  .flight-result-indicator {
    color: #05004e !important;
  }
  .filter-text {
    color: #05004e !important;
  }
  .search-block .class-container .ant-radio-wrapper {
    color: #fff !important;
  }
  .flight-filters .filter-labels p span:nth-child(1) {
    color: #05004e !important;
  }
  .flight-filters .filter-labels p span:nth-child(2) {
    color: #e12001 !important;
  }
  .class-type {
    color: #000000 !important;
  }

  .modify-combined-search-container {
    .modify-combined-block {
      max-width: 1200px;
      margin: auto;
      padding: 4px 0;
      .hotels-hide-search {
        margin: 10px;
        float: right;
        cursor: pointer;
        background-color: #e12001;
        color: #fff;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 13px;
      }
      .ant-collapse {
        background: transparent;
        border: none;
        .ant-collapse-item {
          border: none;
          text-align: right;
        }
        .ant-collapse-header {
          padding: 0;
          display: none;
          @include mobile {
            display: inline-block;
          }
        }
        .ant-collapse-content {
          text-align: left;
          border: none;
          background: transparent;
          .ant-collapse-content-box {
            padding: 0;
            .hotels_search_box {
              background: transparent;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .tabs-container {
    padding: 10px 0px;
    max-width: 1200px;
    margin: auto;
  }
  .search-block {
    .search-body {
      background: none;
      border: none;
      padding: 0;
    }
  }
  .train-filters-block {
    background-color: #fff;
    padding: 24px !important;
  }
  .tab-blocks {
    font-size: 18px;
    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .ant-tabs-tab {
    width: 65%;
  }
  .flight-results-skeleton-container {
    padding: 0;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-nav-animated {
    margin-left: 313px;
  }
  .ant-tabs-tab {
    color: #05004e !important;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      color: #e12001 !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .ant-tabs-tab-active {
    color: #e12001 !important;
  }
  .ant-tabs-ink-bar {
    background-color: #e12001 !important;
    height: 4px !important;
    width: 121px !important;
  }
}
.fbt-tabs .ant-tabs-nav-list {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: block !important;
}
.fbt-tabs {
  overflow: auto !important;
}
.fiters-value-hotel-1 {
  display: none !important;
}

.flight-class {
  font-size: 12px;
  font-weight: 600;
}

.tabs-fbt-space .ant-tabs-nav-list {
  display: block !important;
}
.hous-fbt {
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-bottom: 0;
    @include mobile {
      font-size: 12px;
    }
  }
  .anticon {
    margin: 0 0 0 6px;
    font-size: 14px;
  }
  .ant-skeleton-element,
  .ant-skeleton-button {
    width: 100%;
  }
}
.fbt-time {
  margin-bottom: 0px;

  font-size: 14px;
}
.fiters_wrapper {
  display: none !important;
}
@include mobile {
  .fiters_wrapper {
    display: flex !important;
    position: fixed;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 2;
    background: #009966;
    .tc {
      text-align: center;
    }
    .title {
      display: inline-block;
      padding: 12px 0;
      font-size: 1rem;
      color: #fff;
      cursor: pointer;
    }
  }

  .radio-sort {
    border: 2px solid #009966;
    padding: 10px !important;
    border-radius: 5px;
    align-items: center;
  }
  .fbt-tabs .ant-tabs-nav-list {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: inline-flex !important;
  }
  .filter-section-flight {
    display: none;
  }

  .filter-section-bus {
    display: none;
  }
  .filters-sec-trains {
    display: none;
  }
  .margins-alignments {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sort-by-filtef .sort-block {
    display: block;
  }
  .tabs-fbt-space .ant-tabs-nav-list {
    display: flex !important;
  }
  .hous-fbt {
    display: block;
  }

  .fbt-time {
    margin-left: 31px;
  }
}

.mobBusSortWrapper {
  .sort-block {
    @include mobile {
      display: block;
    }
    .sort-header {
      display: none;
    }
    .ant-radio-wrapper {
      border: none !important;
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
    .price-type {
      padding: 0 !important;
      font-size: 14px !important;
    }
  }
}

.mobflightsort {
  .sort-block {
    @include mobile {
      display: block;
    }

    .sort-header,
    .matrixWrapper {
      display: none;
    }
    .ant-radio-wrapper,
    .ant-card-bordered {
      border: none !important;
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
    .price-type {
      padding: 0 !important;
      font-size: 14px !important;
    }
  }
}

.airport-no-facility p {
  margin-bottom: 0px;
  font-size: 14px;
}
.fareRule-container pre {
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif !important;
}

@media screen and (max-width: 768px) {
  .infobtnwrapper .title {
    padding: 8px 3px !important;
  }
  .combined-results-container .tabs-container {
    padding: 0px 0px !important;
  }
  .hotels-hide-search {
    margin: 0px !important;
  }
}
.modify-combined-block .ant-tabs-nav-list {
  display: none !important;
}
.modify-combined-block .ant-tabs-content-holder {
  position: relative !important;
  margin-top: 0px !important;
  right: 0px !important;
}
