.flight-filters {
  .bus-filter-header {
    display: flex;
    justify-content: space-between;
  }
  .flight-result-indicator {
    p {
      font-weight: bold;
      color: #000;
    }
  }
  .overall-filter-container {
    .overall-filter-header {
      display: flex;
      justify-content: space-between;
      .filter-text {
        font-weight: bold;
        color: #000;
      }
      .clear-text {
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;
        color: #0775e2;
      }
    }
    .overall-filter-body {
      .ant-collapse {
        background: transparent;
        border: transparent;
        .ant-collapse-item {
          border: transparent;
          .ant-collapse-header {
            padding: 12px 0;
            font-weight: bold;
            color: #000;
            // .anticon {
            //     width: 100%;
            //     text-align: end;
            //     left: 0;
            // }
          }
          .ant-collapse-content {
            .ant-slider-handle,
            .ant-slider-track {
              background-color: #009966 !important;
              border: none;
            }
            .ant-slider-handle {
              width: 16px;
              height: 16px;
              margin-top: -6px;
            }
          }
        }
      }
      .ant-slider {
        .ant-slider-handle {
          background-color: #009966 !important;
          border: none !important;
        }
      }
      .stops-filter,
      .slider-filter,
      .airlines-filter,
      .fare-filter,
      .fare-types-filter,
      .connecting-locations {
        .ant-collapse-content-box {
          padding: 16px 0;
        }
      }
      .input-range__slider,
      .input-range__track--active {
        background-color: #009966 !important;
        border: none;
      }
    }
  }
  .ant-slider {
    .ant-slider-handle {
      background-color: #009966 !important;
      border: none;
    }
    .ant-slider-track {
      background-color: #009966;
    }
  }
  .filter-labels {
    p {
      margin-bottom: 0;
      span {
        &:nth-child(2) {
          margin-left: 15px;
          font-size: 12px;
          font-weight: normal;
          color: #0775e2;
        }
      }
    }
  }
  .ant-collapse-header {
    .ant-collapse-extra {
      span {
        margin-right: 15px;
        font-size: 13px;
        font-weight: 600;
        color: #0775e2;
      }
    }
  }
  .ant-collapse-content-box {
    .slider-label {
      display: flex;
      justify-content: space-between;
      span {
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }
}

.slider-icon-1 .ant-slider {
  margin: 10px 8px !important;
}
.slider-icon-1 .input-range {
  height: 1rem;
  position: relative;
  width: 93%;
}

.slider-icon-1 .input-range__slider:active {
  transform: scale(1);
}

.slider-icon-1 .input-range__slider {
  margin-left: 0rem;
  margin-top: -0.65rem;
}
.slider-icon-1
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 0px;
  left: auto;
  bottom: 13px;
}
.slider-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .f-tabs-side {
    display: none;
  }
  #mobile-side-fbtabs {
    display: block !important;
  }
}
.flight-ant-collease .ant-collapse-content-box {
  background-color: rgba(0, 0, 0, 0.03);
}
.filters-fonts .ant-checkbox + span {
  font-size: 13px;
}
.filters-fonts .slider-label span {
  font-size: 13px;
}

.fl-tops-bar {
  position: relative;
  top: -10px;
}
.input-range__slider {
  background-color: #009966 !important;
}
.input-range__track--active {
  background-color: #009966 !important;
}
