.icon-info {
  margin-right: 9px;
  width: 41px;
  background: #002273;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
}
.top-fl-route {
  color: #000;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 35px;
}
.top-fl-route::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}
.tab-content ul {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}
.tab-content ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 4px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}
.tab-content ul li:last-child {
  border-bottom: 0;
}
.hm_link {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}
.srch_btn {
  background: #fff;
  float: right;
  color: #009966;
  border-radius: 4px;
  border: 1px solid #009966;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;
  min-height: 36px;
  /* min-width: 88px; */
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.top-route-bb {
  background-color: #ffffff;
  margin-bottom: 0px;
}
.sec-bg-top {
  width: 100%;
  background: #fff;
  border: 1px solid #dfe2e2;
  border-bottom: 2px solid #dfe2e2;
  margin-top: 35px;
  border-radius: 3px;
  display: inline-block;
  padding: 1% 2%;
}
.gap-cols-sec {
  padding-left: 8px;
  padding-right: 8px;
}
.offers-bal-cart {
  display: flex;
  background: #fff;
  border: 1px solid #e5f1ee;
  border-radius: 4px;

  color: #000;
  padding: 7px 6px;
  position: relative;
  z-index: -1;
}
.deals-coloums-24 {
  height: 34px;
}
.price-deals-24 p {
  margin-bottom: 0px !important;
  font-size: 14px;
}
.price-deals-24 {
  padding-left: 10px;
}
.support-24 {
  padding-left: 13px;
}
@media screen and (max-width: 768px) {
  .support-24 {
    padding-left: 0px;
  }
  .step-img-value {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .step-img-tai {
    width: 100%;
  }
  .book-step-option {
    margin-bottom: 10px;
  }
}
.home-best-24 {
  padding-top: 25px;
}
.home-best-trip p {
  margin-bottom: 8px;
}
.home-best-trip {
  margin-bottom: 0px;
}
/*18-09-2021*/
.top-fl-route-email {
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 16px;
}
.top-fl-route-email::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #fff;
}
.hm_link:hover {
  color: #009966 !important;
}
.srch_btn:hover {
  background: #009966 !important;
  color: #fff;
}
.route-trip-3 {
  margin-bottom: 36px;
}
