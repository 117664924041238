.deals-wrapper {
  padding: 0 0px;
  h5 {
    font-weight: 700;
    color: #444 !important;
    font-size: 26px;
  }
  .ant-card-body {
    padding: 10px;
  }
  .map-cityname {
    color: #444;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .rsdeals__item {
    padding: 5px 0;
    .deal-rs__green {
      color: #008a04;
      font-size: 22px;
      font-weight: 700;
      span {
        font-size: 12px;
      }
    }
    .deals-details {
      border-left: 1px dotted #958a8a;
      color: #444;
      font-weight: 500;
      font-size: 14px;
    }
    .bottom-text {
      color: #444;
    }
    .deals__range {
      width: 100%;
      margin-top: 0.2rem;
      .deals-green {
        background: #008a04;
      }
      .deals-yellow {
        background: #fbbc02;
      }
      .deals-red {
        background: #ea4335;
      }
      .deals-rs__range {
        display: inline-block;
        height: 0.25rem;
        border-radius: 0.188rem;
        width: 33.33%;
      }
    }
  }
}
