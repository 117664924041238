@import "../../assets/variableStyles.scss";

.combined-navbar-header {
  /* position: absolute; */
  z-index: 1;
  background-color: #009966;
  width: 100%;
  .combined-navbar-container {
    max-width: 1270px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
    @include mobile {
      height: 52px;
    }
    .ant-menu-vertical {
      border: none;
    }
  }
  .head-menu {
    background: transparent;
    height: 100%;
    .ant-menu-item {
      height: 100%;
      margin-top: 0;
      line-height: initial;
      display: flex;
      align-items: center;
      padding: 0 25px;
      @include tab {
        padding: 0 15px;
      }
      &.ant-menu-item-selected {
        background: transparent;
        box-shadow: inset 0 -2px 0 #FFF;
      }
      .fa {
        margin-right: 0;
      }
      a {
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        i.fa {
          font-size: 20px;
          margin-bottom: 5px;
        }
        &.ant-dropdown-link {
          flex-direction: row;
        }
      }
      &:hover {
        box-shadow: inset 0 -2px 0 #FFF;
      }
      &.menu-login-item {
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
      }
      &.md-show {
        display: none;
      }
      &.md-hide {
        display: flex;
      }
      @include mobile {
        &.md-show {
          display: flex;
          a {
            flex-direction: row;
          }
        }
        &.md-hide {
          display: none;
        }
      }
    }
  }
}
.menu-items {
  background-color: transparent;
  display: flex;
  border: none;
  color: #05004e;
  .ant-menu-item-only-child {
    a {
      color: #05004e;
    }
    &:nth-child(2) {
      // border: 0px solid #fff;
      // border-radius: 5px;
      &:focus {
        border-radius: 5px;
      }
      &:hover {
        color: #04598f;
      }
    }
  }
  .ant-menu-item-selected {
    background-color: transparent;
  }
}
.logo {
  padding-left: 10px;
  display: flex;
  align-items: center;
  img {
    background-image: url("../../assets/images/trippozo_bg.png");
    background-position: center;
    background-size: cover;
    height: 48px;
    width: auto;
    @include mobile {
      height: 40px;
    }
  }
}
.modal-login-new .ant-modal-footer {
  display: none;
}

.modal-login-new {
  border-radius: 8px;
  width: 368px !important;
  height: auto;
}

.modal-login-new .ant-modal-content {
  border-radius: 8px !important;
  height: 300px;
}

.login-person {
  text-align: center;
  margin-bottom: 50px !important;
  font-size: 18px;
  line-height: 32px;
  color: #132968;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}
.modal-login-new .ant-modal-close-x {
  color: #000 !important;
}

.login-bdr {
  border: 0px solid #fff !important;
  border-radius: 0px !important;
}

.login-person-1 {
  text-align: center;
  margin-bottom: 20px !important;
  font-size: 18px;
  line-height: 32px;
  color: #444;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}

.email-mob {
  height: 45px;
}

.mail-img-mob {
  width: 36px;
  height: 36px;
}
.mobile-fn-sz {
  font-size: 38px !important;
}
.login-person-sub {
  text-align: center;
  margin-bottom: 0px !important;
  font-size: 18px;
  line-height: 32px;
  color: #132968;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
}
// .first-mwnu-item a {
//   border-bottom: 1px solid;
// }
// .ant-menu-item-selected a {
//   color: #ed1c24 !important;
//   border-bottom: 1px solid #ed1c24 !important;
// }
// .ant-menu-item-selected {
//   color: #ed1c24 !important;
//   border-bottom: 2px solid #000 !important;
// }
// .menu-items .ant-menu-item:hover {
//   color: #000 !important;
// }
.md-v3-account {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .md-v3-account {
    display: block !important;
  }
  .v3-menu-mob {
    display: none !important;
  }
  .mobile-callv3 {
    display: none !important;
  }
  .icons-menu-v3 .ant-menu-item a {
    display: inline-block !important;
  }
  .top-menu-support {
    display: none !important;
  }
  .second-header-one {
    background: transparent !important;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.v3-call-cus {
  width: 30px;
}
.mobile-callv3 {
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 13px;
  .call-para {
    display: flex;
    flex-direction: column;
    line-height: 14px;
    margin-left: 6px;
  }
  .phone-icon {
    font-size: 28px;
  }
}

.icons-menu-v3 .ant-menu-item a {
  display: block;
  text-align: center;
  color: #444;
  font-size: 16px;
  padding: 0px 24px 0px 0px;
}
.combined-navbar-container .icons-menu-v3 .v2-pkg1 {
  font-size: 17px;
  margin-right: 0px !important;
}
.icons-menu-v3 .ant-menu-item {
  padding: 0 8px !important;
  margin-bottom: 0px !important;
}
.inner-wdh-v3 {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.second-header-one {
  background: #f4f6f8;
  margin-top: 8px;
  display: block;
  height: 50px;
}
.first-menu-none {
  display: none;
}
.second-header-one .ant-menu {
  border-right: 0px solid #f0f0f0;
  background: transparent;
}

.top-menu-support {
  display: flex;
  align-items: center;
}
.top-menu-support li {
  display: inline-flex;
  padding: 0px 2px 0px 18px;
}
.top-menu-support li a {
  color: #FFF;
  font-size: 12px;
}
.top-menu-support li a:hover {
  color: #DDD;
}
.currency-v3-1 {
  max-width: 15px;
  max-height: 12px;
  margin-right: 10px;
}
.last-mob-bdr {
  padding-top: 7px !important;
}
.link-v3-ff {
  border-bottom: 2px solid #444;
}
// .icons-menu-v3 .ant-menu-item:hover {
//   border-bottom: 2px solid #444;
// }
.ant-modal-close-x {
  height: 24px;
}
.ant-modal-close-icon {
  color: #FFF;
  top: 16px;
  position: absolute;
}
.login-modal {
  .ant-modal-body {
    padding: 0;
  }
}
.mobile-3line {
  border-left: 1px solid #1F7872;
  padding: 0 !important;
  box-shadow: none !important;
  a {
    width: 54px;
    flex-direction: column !important;
    i.fa{
      font-size: 16px !important;
      margin-bottom: 1px !important;
    }
    span {
      font-size: 10px;
    }
  }
}
.tpz-menu-drawer {
  .ant-drawer-header {
    padding: 2px 14px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-menu-inline {
    border-right: 0;
  }
  .ant-menu-item, .ant-menu-submenu {
    font-size: 15px;
  }
  .ant-menu-submenu-title:hover {
    color: initial;
  }
}