.modalHeader {
  .ant-modal-header {
    background-color: #009966 !important;

    .ant-modal-title {
      color: #fff;
    }
  }
}
.hotelImgModal {
  .starRating {
    font-size: 12px;
    color: rgb(246, 149, 45);
    .ant-rate-star {
      margin-right: 2px;
    }
  }
}

.hotels_search_list {
  margin-bottom: 20px;

  .results-wrapper {
    box-shadow: 0 2px 7px #dcdee3;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    background-color: #fff;
    &:last-child {
      margin-bottom: initial;
    }
    .hotel-image {
      grid-column: 1;
      grid-row: 1 / span 3;
      width: 172px;
      position: relative;
      .hotel_image_ {
        height: 172px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 5px 5px;
        }
        .gallery-icon {
          bottom: 0px;
          position: absolute;
          right: 0;
          width: 35px;
          height: 35px;
          background: rgba(28, 43, 57, 0.6);
          border-radius: 3px;
          text-align: center;
          cursor: pointer;
          i {
            font-size: 20px;
            line-height: 35px;
            color: #fff;
          }
        }
      }
    }

    .hotel-title {
      grid-column: 2 / span 2;
      grid-row: 1;
      padding: 0px 15px;

      .hotel-title-wrapper {
        display: flex;
        align-items: baseline;

        .hotel-name {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 700;
          color: #0775e2;
          padding-right: 5px;
          text-transform: capitalize;
          cursor: pointer;
        }
        .hotel-star-rating {
          li {
            margin-right: 2px !important;
          }
        }
      }
      .hotel-star {
        .starRating {
          font-size: 12px;
          color: rgb(246, 149, 45);
          display: block;
          .ant-rate-star {
            margin-right: 2px;
          }
        }
      }
      .hotel-address {
        color: #333;
        font-size: 12px;
        display: flex;
        align-items: center;
        .anticon {
          color: #ea1b37;
          margin-right: 5px;
        }
        .hotel-address-title {
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
        }
        // span {
        //   margin-right: 10px;
        //   padding: 2px 10px;
        //   background-color: #eaebee;
        //   -moz-border-radius: 10px;
        //   border-radius: 10px;
        //   font-size: 10px;
        // }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .hotel-desc {
        margin: 5px 0;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #444;
      }
      .hotel-all-desc {
        margin: 5px 0;
        font-size: 12px;

        overflow: hidden;
        text-overflow: ellipsis;
        color: #444;
      }
      .showmore-dec {
        font-size: 13px;
        padding: 0;
        border: none;
        height: auto;
        color: #0775e2;
        cursor: pointer;
      }
      .tripadvisor-rating {
        display: flex;
        justify-content: space-between;
        background: #f5f8fd;
        border-radius: 5px;
        cursor: pointer;
        .rating-wrapper {
          display: flex;
          align-items: center;
          .rating-number {
            font-size: 14px;
            padding: 10px;
            font-weight: 600;
            letter-spacing: 3px;
            .sec {
              font-size: 12px;
              font-weight: normal;
              color: #969dac;
            }
          }
          .traveller-count {
            padding: 10px;

            p {
              margin-bottom: 3px;
              font-size: 10px;
              color: #969dac;
              font-weight: 400;
              font-family: "Noto Sans Bold", sans-serif;
              text-transform: capitalize;
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
            .rating-count-value {
              display: flex;
              align-items: center;
              .tripAd {
                font-size: 14px;
                color: #000;
                margin-right: 5px;
              }
              .tripRating {
                font-size: 10px;
                color: #00aa6c;
                .ant-rate-star {
                  margin-right: 2px;
                }
              }
            }
          }
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
    }

    .hotel-facilities {
      grid-column: 2 / span 2;
      grid-row: 2;
      padding: 0 15px;
      .amenity-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p {
          font-size: 13px;
          margin-bottom: 0px;
          margin-right: 10px;
          color: #444;
          img {
            margin-right: 5px;
            height: 15px;
          }
          .fa {
            margin-right: 8px;
          }
        }
        .anticon {
          margin-bottom: 5px;
        }
      }
      .showmore-am {
        font-size: 13px;
        padding: 0;
        border: none;
        height: auto;
        color: #0775e2;
      }
    }
    .hotel-price-box {
      grid-column: 4;
      grid-row: 1 / span 3;
      background-color: #f6f7f8;
      text-align: center;
      width: 210px;
      display: grid;
      align-items: center;
      cursor: pointer;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      .hotel-price-box-wrapper {
        .hotel-price {
          color: #969dac;
          font-size: 18px;
          margin-bottom: 15px;
          span {
            color: #1c2b39;
            line-height: 27px;
            font-size: 24px;
            font-weight: 700;
          }
          @media screen and (max-width: 768px) {
            position: absolute;
            right: 60px;
          }
        }
        .hotel-choose-btn {
          padding: 0 30px;
          .web-choose-btn {
            background-color: #009966;
            border-color: transparent;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            border-radius: 3px;
            height: auto;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          .mobile-choose-btn {
            width: 40px;
            height: 40px;
            background-color: #009966;
            border-color: transparent;
            @media screen and (min-width: 768px) {
              display: none;
            }
          }
          @media screen and (max-width: 768px) {
            padding: 0;
          }
        }
        .pax-info {
          margin-top: 10px;
          p {
            color: #505c75;
            max-width: 120px;
            margin: auto;
            font-size: 13px;
            @media screen and (max-width: 768px) {
              max-width: initial;
            }
          }
          @media screen and (max-width: 768px) {
            position: absolute;
            top: 25px;
            right: 60px;
          }
        }
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: flex-end;
          position: relative;
          padding: 10px;
        }
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      .hotel-image {
        grid-column: 1;
        grid-row: 1;
      }

      .hotel-title {
        grid-column: 2 / span 3;
        grid-row: 1;
      }

      .hotel-facilities {
        grid-column: 1 / span 4;
        grid-row: 2;
      }
      .hotel-price-box {
        grid-column: 1 / span 4;
        grid-row: 3;
      }
    }
  }

  .nav {
    width: 100%;
    border: none;
    .nav-link,
    .nav-link.active {
      text-align: center;
      background-color: #ea1b37;
      border-color: transparent;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      border-radius: 3px;
      height: auto;
      max-width: fit-content;
      margin-left: auto;
    }
  }
}
/* oct--09-2020--css-added*/

@media screen and (max-width: 768px) {
  .hotels_search_list .results-wrapper {
    display: block !important;
  }

  .hotels_search_list .results-wrapper .hotel-image {
    grid-column: 1;
    grid-row: 1 / span 3;
    width: 100% !important;
    position: relative;
  }
  .hotels_search_list
    .results-wrapper
    .hotel-price-box
    .hotel-price-box-wrapper
    .hotel-price {
    position: absolute;
    right: auto !important;
  }

  .hotels_search_list
    .results-wrapper
    .hotel-price-box
    .hotel-price-box-wrapper
    .pax-info {
    right: auto !important;
  }
  .hotels_search_list
    .results-wrapper
    .hotel-price-box
    .hotel-price-box-wrapper
    .hotel-choose-btn
    .mobile-choose-btn {
    float: right;
  }

  .hotels_search_list
    .results-wrapper
    .hotel-price-box
    .hotel-price-box-wrapper {
    display: block !important;
  }
}
.hotel-panel-res .nav-link {
  display: none;
}
.hotel-panel-res .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
@media screen and (max-width: 768px) {
  .hotel_image_ {
    height: 150px !important;
  }
}
