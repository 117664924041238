@import "../assets/variableStyles.scss";
@import "./Deposits/Deposits.scss";
$primary: #e12001;
$secondry: #05004e;

#admin {
  h5,
  h6 {
    color: $secondry !important;
  }
  .primaryBg {
    background: $primary;
  }
  .primaryColor,
  .travelDateContent span {
    color: $primary;
  }

  .secondryBg {
    background: $secondry;
  }
  .secondryColor {
    color: $secondry;
  }

  .ant-modal-header,
  .ant-radio-button-wrapper-checked {
    background-color: $primary !important;
    border-color: $primary;
  }

  .ant-btn-danger {
    color: white !important;
  }
  .ant-table-thead tr th {
    background: #009966 !important;
  }
  .ant-table-content td,
  .ant-picker-calendar-date-value {
    color: $secondry !important;
  }
  .ant-upload .anticon {
    color: $secondry;
  }
  .ant-btn {
    background-color: #009966 !important;
    border-color: #009966 !important;
    color: #fff;
    &:hover {
      background: #005f3f !important;
      background-color: #005f3f !important;
    }
  }
  .logo {
    height: 64px !important;
    width: auto !important;
    overflow: hidden;
    padding: 0 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100% !important;
      height: auto;
      max-height: 50px;
    }
  }
  .ant-layout-sider {
    background: $secondry;
  }
  aside ul {
    background: $secondry;
    color: white;
  }

  .ant-menu-item-selected {
    background: $primary;
  }
  .ant-collapse-header {
    color: $secondry;
    font-weight: 600;
    .anticon-caret-right {
      font-size: 14px;
    }
  }
  .ant-form-item-label label,
  .upload-logo p,
  .ant-upload .ant-upload-text {
    color: #000000 !important;
    font-weight: 600;
  }

  header {
    z-index: 9;
  }
  .adminFooter {
    text-align: center;
    margin: 0;
  }
  .anticon-upload,
  .ant-radio-button-wrapper-checked,
  .ant-picker-content tr th {
    color: #fff !important;
    font-weight: 600;
  }
  .ant-picker-content tr th {
    background-color: $primary;
    border: 1px solid #fff;
    padding: 5px 12px;
  }

  .ant-picker-calendar-date-today {
    border-color: $secondry;
  }
  .eyewrapper {
    text-align: center;
    color: green;
  }
  .eyered {
    color: red;
  }
  .profile_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .profile-details {
    .profile-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #ccc;
      margin: 10px 0px;
    }
  }
  .profile-update {
    .member-since {
      display: flex;
      justify-content: space-between;
      // color: #1890ff;
      font-weight: 600;
    }
    .update-btn {
      button {
        border: none;
        width: 100%;
      }
    }
  }
}
.ant-table-thead {
  white-space: nowrap;
}
.ant-table-tbody {
  white-space: nowrap;
}

@include mobile {
  .manage-markup-section {
    margin-top: 18px;
    padding: 0 !important;
  }
  .ant-card-body {
    padding: 15px;
  }
  .card-add-heading {
    flex-wrap: wrap;
    .rows-count {
      max-width: 100%;
      width: 100%;
    }
    h5 {
      margin-bottom: 0;
      font-size: 15px;
    }
    .add-icon {
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    }
  }
  .ant-table-thead {
    tr {
      th {
        width: 100px !important;
      }
    }
  }
  .ant-modal-content {
    width: 90%;
    margin: auto;
  }
}

.site-layout-background {
  background: #fff;
}
.headerColor {
  .anticon {
    color: $secondry;
  }
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
@include mobile {
  // .promo-modal-header {
  //     overflow-x: hidden;
  // }
}
.ant-modal-title {
  color: #fff !important;
}

.seperation-line {
  border-top: solid 1px #ccc;
  padding-top: 20px;
}

.card-gap {
  margin-top: 20px;
  .ant-card-head {
    background: #1890ff !important;
    color: #fff !important;
  }
  .flight-depart-details {
    .flight-details {
      border-top: solid 1px #ccc;
      padding-top: 30px;
      margin-top: 20px;
      .flight-title {
        text-align: center;
      }
    }
  }
}
#admin {
  .ant-menu {
    .fa {
      margin-right: 10px !important;
    }
  }
  .ant-layout-sider-collapsed {
    @include mobile {
      // min-height: 100vh;
      flex: 0 0 60px !important;
      width: 60px !important;
      min-width: 60px !important;
      max-width: 60px !important;
    }
  }
  .ant-menu-inline-collapsed {
    @include mobile {
      width: 60px !important;
      text-align: center;
    }
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 32px !important;
    line-height: 32px;
    @include mobile {
      margin: 0 !important;
      padding: 0 16px !important;
    }
  }
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 32px !important;
    line-height: 28px;
    @include mobile {
      padding: 0 16px !important;
    }
  }
  .ant-layout-header {
    height: 70px !important;
    line-height: 70px !important;
    padding: 0px 13px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    @include mobile {
      padding: 0px !important;
    }
  }
}
.bookoffline-submit {
  text-align: right;
  button {
    background: #1890ff;
    color: #fff;
    font-size: 20px;
    padding: 20px 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-flex {
  display: flex;
}

/* settings */

.gst-section {
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}
.left-details {
  @include mobile {
    order: 1;
    margin-top: 25px;
  }
}
/* API logs */
.copy-btn {
  text-align: right;
  button {
    background: #1890ff;
    color: #fff;
    border: none;
    font-size: 12px;
    height: 27px;
    &:hover {
      background-color: #fff;
      border: solid 1px #1890ff;
    }
  }
}

.action-images {
  img {
    cursor: pointer;
  }
}
.admin-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.view-dn {
  position: relative;
  top: -51px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}
.all-view-h5 {
  position: relative;
  top: -20px;
}

.view-dn-new {
  position: relative;
  top: -42px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}
.all-view-h5-new {
  position: relative;
  top: -20px;
}

.inner-drop-down {
  padding-left: 50px !important;
}
/*09-09-2020--changes--*/
.admin-preference {
  width: 900px !important;
}

.ant-table-content {
  overflow: scroll;
}

.ant-table-pagination-right {
  -ms-flex-pack: end;
  justify-content: center;
}
#admin {
  .ant-table-tbody {
    line-height: 0.5715 !important;
    font-size: 13px !important;
  }
  .ant-table-thead {
    line-height: 0.5715 !important;
    font-size: 13px !important;
  }
}
