// @import "admin/adminIndex.scss";
@import "./assets/variableStyles.scss";
$primary: #e12001;
$secondry: #05004e;
@font-face {
  font-family: "Ubuntu";
  src: url(assets/fonts/Ubuntu/Ubuntu-Bold.ttf) format("truetype");

  // src: url(assets/fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
  src: url(assets/fonts/Ubuntu/Ubuntu-Light.ttf) format("truetype");
  // src: url(assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format("truetype");
  // font-weight: normal;
}
@font-face {
  font-family: "Noto Sans";
  src: url(assets/fonts/NotoSans/NotoSans-Regular.ttf) format("truetype");
  // src: url(assets/fonts/NotoSans/NotoSans-Bold.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Noto Sans", sans-serif !important;
  color: $primary;
  font-size: 14px;
}
#root {
  height: 100%;
}
ul {
  padding: 0;
  margin: 0 !important;
}
li {
  list-style-type: none;
}
a,
a:hover {
  text-decoration: none !important;
}
textarea:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: transparent;
  // box-shadow: 0 0 1px #ddd, inset 0 0px 1px #ddd;
  outline: 0 none;
}
input[type="number"] {
  -moz-appearance: textfield !important; /* Firefox */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;

  appearance: none;
  margin: 0;
}

input::placeholder {
  color: #707070;
  font-weight: 400 !important;
}
input {
  color: #434343 !important;
}
button:focus {
  outline: transparent;
}
.e-hide {
  display: none !important;
}
.e-visible {
  display: block !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: $secondry !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: $primary !important;
}
.ant-modal-content .ant-btn,
.reports-btns .ant-btn {
  background-color: #009966;
  border-color: #009966 !important;
  color: #fff;
  &:hover {
    background: #005f3f !important;
    background-color: #005f3f !important;
  }
  &:focus {
    background-color: $primary;
    border-color: $primary !important;
    color: #fff;
  }
}
h5,
h6 {
  color: #05004e !important;
  font-weight: 600;
}
.ant-table-thead tr th {
  background: $primary !important;
  color: #fff !important;
}
.ant-table-content td {
  color: $secondry !important;
}
@include tab {
  .e-md-visible {
    display: block !important;
  }
  .e-md-hide {
    display: none !important;
  }
}
@include desktop {
  .e-lg-visible {
    display: block !important;
  }
  .e-lg-hide {
    display: none !important;
  }
}

.successModalBox {
  width: 600px !important;
  .ant-modal-close-x {
    height: 84px;
    color: #4e4e4e !important;
  }
  .ant-modal-header {
    background-color: #fff !important;
    .ant-modal-title {
      color: $secondry !important;
      font-size: 24px;
      padding: 15px 0;
      text-align: center;
      @include mobile {
        font-size: 18px;
        padding: 5px 20px 0 0;
      }
    }
  }
  .ant-modal-body {
    padding: 24px 10px;
    .sucessModal {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #f0f0f0 !important;
      .modalIcon {
        font-size: 58px;
        margin-bottom: 10px;
        color: #1cad61;
        @include mobile {
          font-size: 48px;
        }
      }
      h6 {
        margin-bottom: 20px;
        color: #1cad61;
        font-weight: 600;
        @include mobile {
          font-size: 14px;
        }
      }
      p {
        margin-bottom: 5px;
        @include mobile {
          font-size: 12px;
        }
      }
    }
    .socialLinks {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .socialwrap {
        width: 60px;
        color: #9a9a9a;
        text-align: center;
        @include mobile {
          width: 40px;
        }
        .anticon {
          font-size: 18px;
        }
      }
      .sharetext {
        @include mobile {
          display: block;
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}

.ant-select-dropdown {
  z-index: 9999 !important;
}
@include print {
  .main_body {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    .ant-card-body,
    .ant-collapse-content-box {
      padding: 0 !important;
      .ant-table-thead tr th {
        background: #e12001 !important;
        color: #fff !important;
      }
    }

    .fligh-ticket-container {
      padding: 15px !important;
      margin: 0 !important;
      width: 100% !important;
    }
    .ticket-row {
      flex-direction: column !important;
      .ant-col {
        max-width: 100% !important;
      }
      .combined-navbar-header,
      .tic-info-flight,
      .footer_content {
        display: none !important;
      }
    }
    .combined-navbar-header,
    .tic-info-flight,
    .footer_content {
      display: none !important;
    }
  }
  #admin {
    .ant-layout {
      .ant-layout-sider {
        display: none !important;
      }
      .logo {
        display: block !important;
      }
      .ant-layout {
        .ant-layout-header {
          display: none !important;
        }
      }
    }
    .tic-info-flight {
      display: none !important;
    }
    .admin-ticket-block {
      width: 100% !important;
      max-width: 100% !important;
      flex: 1 !important;
    }
    .ant-layout-footer {
      display: none !important;
    }
  }
}

iframe {
  width: 100%;
  height: 400px;
}
