.manage-markup-section {
  background-color: #f6f7fb;
  padding: 20px;
  .card-bt-gap {
    margin-bottom: 20px;
    .ant-collapse-item {
      background-color: #fff;
    }
  }
  .card-add-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reports-btns {
    display: flex;
    justify-content: flex-end !important;
    button {
      margin-right: 17px;
    }
  }
  .table-heading {
    text-align: center;
    p {
      margin-bottom: 0px;
    }
    span {
      color: red;
    }
    .total-amounts {
      margin-top: 20px;
    }
  }
}
// #passportModal {
// .ant-modal {
//     // width: 700px !important;
//     // transform-origin: 380px 193px !important;
//     .ant-modal-content {
//         width: 700px !important;
//     }
// }
// }

.ant-table-pagination-right {
  float: none !important;
  text-align: center !important;
  padding-top: 20px !important;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.anticon .anticon-plus {
  color: #007bff !important;
}

.ant-upload-select-picture-card i {
  color: #007bff;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #007bff;
}

.table-scroll-none {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.gstDetails {
  .gst-content {
    display: flex;
  }
}

/* ANTD Modal custom styles */

.ant-modal-close-x {
  color: #fff !important;
}
.ant-modal-header {
  color: #fff;
  /*  background: #1890ff !important; */
  h6 {
    color: #fff !important;
  }
}

/* ANTD Modal custom styles */
