.web-check-in-page {
  background-image: url("../../assets/images/web-air-line-desk.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  //background-size: cover;
  height: 335px;
  width: 100%;
  margin-bottom: 20px;
}
.web-heading-check {
  font-size: 16px;
  // margin-left: 10px;
  font-weight: 500;
  line-height: 3px;
}
.span-web-check {
  color: #999;
  //margin-left: 10px;
  font-size: 13px;
}
.list-web-checkin {
  display: flex;
  color: #333;
  background-color: #fff;
  margin: 10px;
  //display: inline-block;
  //min-width: 173px;
  //width: 18%;
  box-shadow: 0 1px 3px rgb(0, 0, 0 / 10%);
  border-radius: 8px;
  transition: all 0.5s ease;
  padding: 12px 12px;
  cursor: pointer;
}
.list-web-checkin:hover {
  transition: all 0.5s ease;
  box-shadow: 0 1px 3px rgb(0, 0, 0 / 20%);
  transform: scale(1.1) perspective(1px);
  backface-visibility: hidden;

  -webkit-font-smoothing: subpixel-antialiased;
}
.list-cc-img-web {
  border-radius: 50%;
  height: 28px;
  width: 28px;
}
.list-web-checkin span {
  margin-bottom: 0px;
  color: #333;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 3px;
}
.panel-webcheckin {
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .web-check-in-page {
    background-image: url("../../assets/images/web-checkin-mob-1.png");
    height: 150px;
  }
}
