.flight_search_box {
  input {
    border-color: #bcd5f5;
  }
  background: #fff;
  // padding: 0 20px;
  border-radius: 5px;
  // margin-top: 0;
  .flight-header {
    margin-bottom: 4px;
    label {
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      &:active {
        color: #1c2b39;
        font-weight: 700;
      }
    }
    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }
      .col {
        padding-left: 0;
        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 700;
          line-height: 19px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;
      label {
        font-size: 11px;
        font-weight: 700;
        color: #1c2b39;
        margin-bottom: 0;
      }
      .flight-search-btn {
        background-color: #ea1b37;
        color: #fff;
        border-color: transparent;
        &:hover {
          background-color: #ea1b37;
        }
      }
    }
    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: FontAwesome;
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          &:hover {
            color: red !important;
          }
        }
      }
    }
    .pax-modal {
      position: absolute;
      z-index: 1;
      left: -101px;
      background: #fff;
      width: 100%;
      // margin-top: 20px;
      .pax-modal-wrapper {
        padding: 10px 20px;
        border: 1px solid #bcd5f5;
        li {
          display: flex;
          list-style-type: none;
          border-bottom: 1px solid #dcdee3;
          margin-bottom: 2px;
          margin-top: 8px;
          padding-bottom: 8px;
          &:last-child {
            border-bottom-color: transparent;
          }
          .pax-label {
            flex-grow: 1;
            p {
              margin-bottom: -6px;
              font-size: 16px;
              font-weight: bold;
              color: #1c2b39;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #969dac;
            }
          }
          .pax-count {
            flex-grow: 1;
            align-self: baseline;
            display: flex;
            justify-content: space-evenly;
            .fa {
              background: #0775e2;
              padding: 4px;

              color: #fff;
              line-height: 1;
              vertical-align: sub;
            }
            .fa-minus {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            .fa-plus {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .pax-ready-btn {
          border-radius: 3px;
          background: #fff;
          padding: 6px;
          color: #0775e2;
          font-size: 11px;
          font-weight: 700;
          border: 2px solid #0775e2;
          margin-top: 20px;
          &:hover {
            background: #0775e2;
            color: #fff;
          }
        }
      }
    }
  }
  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .place-box {
      width: 37%;
      padding-right: 10px;
      padding-left: 10px;
    }
    .departure-date-box {
      width: 23%;
    }
    .city-increment {
      margin-left: -4px;
    }
  }
}
.search-row {
  align-items: flex-start;
}
.from-to-inputs {
  .ant-row {
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
  }
}
.buses-panel-align-bd .ant-select-selection-search {
  left: 32px !important;
}
.pdr-bus-bottom {
  padding-bottom: 26px;
}

@media screen and (max-width: 768px) {
  .pdr-bus-bottom {
    padding-bottom: 0px !important;
  }
  .firstbusinfo {
    height: 3.4375rem !important;
    margin-bottom: 10px;
  }
  .bus-btn-bottom {
    margin-top: 0px !important;
  }
}
.bus-panel-bg .ant-picker-suffix {
  margin: 0 4px 0 0;
  color: #3b425d !important;
  order: 1 !important;
  font-size: 19px;
}
