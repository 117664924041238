@import "../../assets/variableStyles.scss";

/* Calander component custom styles */
@include mobile {
  .react-datepicker.floating {
    width: 100%;
    .react-datepicker-container {
      width: 100%;
    }
  }
}

.hotels_search_box {
  background-color: transparent;
  padding: 25px 20px;
  border-radius: 5px;
  @include mobile {
    padding: 10px !important;
  }
}
.hotel-search-form {
  .ant-form-item {
    flex-wrap: wrap !important;
    align-items: flex-start;
    @include mobile {
      margin-bottom: 5px;
    }
    label {
      margin-bottom: 0 !important;
    }
  }
  .hotel-autocomplete {
    flex-direction: column;
    .ant-form-item-control {
      width: 100%;
    }
  }
  .ant-form-item-label {
    label {
      font-size: 11px;
      font-weight: 700;
      color: #1c2b39;
      height: 20px;
      display: none;
    }
  }
}
.hotel-search-form {
  align-items: flex-end;
}
.add-room-block {
  position: relative;
}
.pax-modal {
  position: absolute;
  z-index: 1;
  left: -25px;
  background: #fff;
  width: 100%;
  margin-top: 10px;
  min-width: 280px;

  .pax-modal-wrapper {
    padding: 10px 20px;
    border: 1px solid #bcd5f5;
    position: relative;
    .pax-modal-arrow {
      content: "";
      border-left: 10px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 10px solid #bcd5f5;
      position: absolute;
      top: -10px;
      left: 45%;
      @include mobile {
        left: 20%;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 9px solid #fff;
        border-top: 0px solid #fff;
        left: -9px;
        top: 2px;
      }
    }

    ul.first-item {
      padding: 0;
      margin: 0;
      width: 100%;

      .hotel-pax-box {
        &:first-child {
          p.remove-btn {
            display: none;
          }
        }
        li {
          &:last-child {
            border-bottom-color: transparent;
          }

          ul.child-item {
            width: 100%;
            li {
              .lists-wrapper {
                display: flex;
                justify-content: space-between;
                list-style-type: none;
                border-bottom: 1px solid #dcdee3;
                margin-bottom: 2px;
                margin-top: 8px;
                padding-bottom: 8px;
                p.remove-btn {
                  font-size: 12px;
                  color: #0775e2;
                  cursor: pointer;
                  margin-bottom: 0;
                  &:hover {
                    text-decoration: underline;
                  }
                }
                .pax-label {
                  flex-grow: 1;
                  p {
                    margin-bottom: -6px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1c2b39;
                  }
                  span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #969dac;
                  }
                }
                .pax-count {
                  flex-grow: 1;
                  align-self: baseline;
                  display: flex;
                  justify-content: space-evenly;
                  .fa {
                    background: #0775e2;
                    padding: 4px;

                    color: #fff;
                    line-height: 1;
                    vertical-align: sub;
                  }
                  .fa-minus {
                    border-radius: 10px;
                  }
                  .fa-plus {
                    border-radius: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .add-room-btn {
      color: #0775e2;
      font-size: 12px;
      display: inline;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .pax-ready-btn {
      border-radius: 3px;
      background: #fff;
      padding: 6px;
      color: #0775e2;
      font-size: 11px;
      font-weight: 700;
      border: 2px solid #0775e2;
      margin-top: 20px;
      &:hover {
        background: #0775e2;
        color: #fff;
      }
    }
  }
  @include mobile {
    left: 0;
    width: 100%;
    margin-top: 10px;
  }
}
.hotels-wrapper {
  .ant-radio-group {
    margin-bottom: 10px;
    @include mobile {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .ant-radio-wrapper {
      @include mobile {
        width: 100%;
      }
    }
  }
}

.accordion {
  margin-top: 0px;
  .card {
    border: none;
    margin-bottom: 0px;
    overflow: visible !important;
    h2 {
      background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
        no-repeat calc(100% - 10px) center;
      background-size: 20px;
      cursor: pointer;
      font-size: 18px;
      &.collapsed {
        background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
      }
    }
    &-body {
      padding: 0;
    }
  }
}
.hotels-search-card-value {
  background: transparent !important;
}

.hide-icon-heading {
  display: none;
}

/*csss--new--sec--*/
.hotel-bg-panel-all {
  width: 100%;
  // background: #fff;
  border-radius: 3px;
  padding: 0;
  margin: 0 auto;
}

.hotel-bg-panel-all .ant-select-selector {
  font-size: 14px;
  font-weight: 700;
  height: 3.4375rem !important;
  padding: 9px 36px !important;
  border-color: transparent !important;
  border: 0px solid !important;
  border-right-width: 0px !important;
  font-weight: 700;
}
.hotel-bg-panel-all .ant-form-item {
  margin-bottom: 10px !important;
}
.hotel-bg-panel-all .ant-picker-range {
  height: 3.4375rem !important;
  border: 0px solid #d9d9d9 !important;
}
.hotel-bg-panel-all .ant-select {
  //line-height: 4 !important;
}
.hotel-bg-panel-all .ant-input-lg {
  height: 3.3rem !important;
  border: 0px solid #d9d9d9 !important;
  color: #313541;
}

// .hotel-bg-panel-all .search-row {
//   margin-left: 0px !important;
//   margin-right: 0px !important;
// }
.hotel-bg-panel-all .ant-select-lg {
  border-right: 0px solid #d6d3d3;
}
.right-line-bdr {
  border-right: 0px solid #d6d3d3;
}
.last-panel-btn {
  width: 100%;
  height: 3.4375rem !important;
}
.hotel-bg-panel-all .ant-select-selection-item {
  color: #313541;
}
.hotel-bg-panel-all input {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #313541 !important;
  height: 3.4375rem !important;
}

@media screen and (max-width: 768px) {
  .hotel-bg-panel-all .hotel-select-jun {
    // height: auto;
    height: 3.4375rem !important;
    margin-bottom: 10px;
  }
  #hotel-main-btn {
    margin-top: 10px;
  }
  .hotel-bg-panel-all .ant-select-lg {
    border-right: 0px solid #d6d3d3 !important;
    border-bottom: 0px solid #d6d3d3;
  }
  // .right-line-bdr {
  //   border-right: 0px solid #d6d3d3 !important;
  //   border-bottom: 1px solid #d6d3d3;
  // }
  .checin-cheout-mob .ant-picker-panel-container .ant-picker-panels {
    display: block !important;
  }
  .ant-picker-panels {
    display: block !important;
  }
}
.hotel-bg-panel-all #checkin_checkout {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #313541 !important;
}
.hotel-bg-panel-all input {
  font-weight: bold;
}
.tg-primary-btn-h3 {
  background-color: #009966 !important;
  color: #fff;
  border-color: #009966 !important;
  height: 3.4375rem !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  @include mobile {
    width: 100%;
    //margin-top: 10px !important;
  }
  &:hover {
    background-color: #005f3f !important;
    border-color: #005f3f !important;
    color: #fff;
  }
}
.location-name-code {
  position: absolute;
  // height: 100%;
  width: 30px;
  display: flex;
  // align-items: center;
  justify-content: center;
  z-index: 1;
  color: #3b425d !important;
  font-size: 21px;
  left: 7px;
  top: 18px;
  @include mobile {
    //top: 26px;
  }
}
.postive-v3-hotel {
  position: relative;
  .hotel-room-res-v3 {
    padding: 0 20px !important;
  }
}
.hotel-bg-panel-all .ant-picker-suffix {
  margin: 0 4px 0 0;
  color: #3b425d !important;
  order: 1 !important;
  font-size: 19px;
}
.hotel-bg-panel-all .ant-select-selection-search {
  left: 32px !important;
}
.hotel-bg-panel-all .hotel-room-res-v3 {
  padding: 0px 40px;
}
.hotel-bg-panel-all .ant-select-selection-placeholder {
  color: #313541;
}
